import { TickerTape } from "react-tradingview-embed";

const Tape = () => {
  return (
    <div className="container-fluid no-fluid">
      <div className="row sm-gutters">
        <div className="col-md-12 mb15">
          <div className="tradingview-widget-container">
            <div className="tradingview-widget-container__widget"></div>
            <TickerTape
              widgetProps={{
                symbols: [
                  {
                    proName: "BITSTAMP:BTCUSD",
                    title: "Bitcoin",
                  },
                  {
                    proName: "BITSTAMP:ETHUSD",
                    title: "Ethereum",
                  },
                  {
                    description: "Shiba",
                    proName: "BINANCE:SHIBUSDT",
                  },
                  {
                    description: "Cardano",
                    proName: "BINANCE:ADAUSDT",
                  },
                  {
                    description: "Solanium",
                    proName: "BINANCE:SOLUSDT",
                  },
                  {
                    description: "Tron",
                    proName: "BINANCE:TRXUSDT",
                  },
                  {
                    description: "Dogecoin",
                    proName: "BINANCE:DOGEUSDT",
                  },
                  {
                    description: "XRP",
                    proName: "BINANCE:XRPUSDT",
                  },
                  {
                    description: "Binance Coin",
                    proName: "BINANCE:BNBUSDT",
                  },
                ],
                showSymbolLogo: true,
                colorTheme: "light",
                isTransparent: false,
                displayMode: "adaptive",
                locale: "en",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tape;
