import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FaTimes } from "react-icons/fa";
import QRCode from "qrcode.react";

interface ModalProps {
  show: boolean;
  onClose: () => void;
  referralLink: string;
  referralCode: string;
}

const QRCodeModal: React.FC<ModalProps> = ({
  show,
  onClose,
  referralLink,
  referralCode,
}) => {
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        open={show}
        onClose={onClose}
        className="fixed inset-0 z-10 overflow-y-auto"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-400"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="relative flex flex-col bg-gray-800 text-white rounded-lg p-10 transform transition-transform duration-300 ease-out scale-100">
                <button
                  type="button"
                  onClick={onClose}
                  className="absolute top-4 right-4 text-white"
                >
                  <FaTimes />
                </button>
                <Dialog.Title className="text-2xl mb-4">
                  Use QR code
                </Dialog.Title>
                <Dialog.Description className="mb-4">
                  Invite friends to register, trade and earn together
                </Dialog.Description>
                <div className="flex items-center justify-center">
                  <QRCode value={referralLink} size={228} />
                </div>
                <p className="mt-4">Referral code</p>
                <p className="text-[#007bff] text-xl">{referralCode}</p>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default QRCodeModal;
