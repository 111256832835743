export enum WithdrawalRequestStatuses {
  notProcessed = 0,
  rejected = 1,
  accepted = 2,
}

export enum BetResults {
  win = "win",
  loss = "loss",
  draw = "draw",
  notProcessed = "not processed",
}

export enum BetTypes {
  high = "high",
  low = "low",
}

export interface User {
  id: string;
  fullName: string;
  email: string;
  password: string;

  country: string;
  phone: string;

  revenue: number;

  isBlocked: boolean;
  email_verified: boolean;

  referredBy: User | null;
  referralCode: string;

  wallet: Wallet;
  cryptoWallet: string;

  createdAt: string;
  status: string;
  level: number;
  referralBalance: number;
  rebateBalance: number;
}

export interface Wallet<T = string> {
  owner: T;
  balance: number;
  active: boolean;
}

export interface WithdrawalRequest<T = string> {
  user: T;
  amount: number;
  status: WithdrawalRequestStatuses;
}

export type CryptoWallet = string;

export interface Deposit {
  hash: string;
  user: User;
  from: string;
  to: string;
  contractAddress: string;
  amount: number;
}

export interface Bet {
  id: string;
  user: User;
  betAmount: number;
  timer: number;
  valueAtPlacing: number;
  prediction: BetTypes;
  profit: number;

  valueAtEvaluation: number;
  confirmed: boolean;

  result: BetResults;

  createdAt: string;
  updatedAt: string;
}
export interface Transaction {
  id: string;
  createdAt: string;
  from: string;
  amount: number;
  contractAddress: string;
  success: true;
}
