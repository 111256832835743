import React from "react";
import TradesHeader from "./TradesHeader";

export default function TradeLayout({ children }: any) {
  return (
    <>
      <TradesHeader />
      <div className="market-page">{children}</div>
    </>
  );
}
