import React, { useContext, useState } from "react";
import { toast } from "react-toastify";

import { UserContext } from "../../context/UserContext";
import { RequestWithdrawalAPI } from "api/common/api";

const Withdraw = () => {
  const { auth, updateUser } = useContext(UserContext);
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [walletAddress, setWalletAddress] = useState(auth.user.cryptoWallet);
  const handleWithdrawChange = (e: any) => {
    e.preventDefault();
    setWithdrawAmount(e.target.value);
  };
  const handleWalletChange = (e: any) => {
    e.preventDefault();
    setWalletAddress(e.target.value);
  };

  const requestWithdrawal = async (e: any) => {
    e.preventDefault();
    const { errors, response } = await RequestWithdrawalAPI({
      auth_token: auth.auth_token,
      amount: +withdrawAmount,
      withdrawalCryptoWallet: walletAddress,
    });
    if (errors) {
      errors.map((err: any) => toast.error(err.message));
      return;
    }
    if (!response || !response.success) {
      // handle "SERVER NOT RESPONDING CORRECTLY" error
      // and then return
      toast.error("Server is not responding correctly. Please Try again.");
      return;
    }
    toast.success(`You have successfully withdrawn ${withdrawAmount}$`);
    updateUser();
  };

  return (
    <div className="card">
      <div className="card-body deposit-steps">
        <h5 className="card-title">Withdraw Steps:</h5>
        <div className="row wallet-address">
          <div className="col-md-8">
            <ol>
              <li>
                <p>Enter your wallet address</p>
              </li>
              <li>
                <p>Enter the amount to withdraw</p>
              </li>
              <li>
                <p>Click withdraw</p>
              </li>
            </ol>
            <label>Wallet Address:</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Your wallet address"
              value={walletAddress}
              onChange={handleWalletChange}
            />
            <br />

            <label>Withdraw Amount:</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Amount to withdraw"
              value={withdrawAmount}
              onChange={handleWithdrawChange}
            />
            <div className="button-wrapper">
              <button
                onClick={requestWithdrawal}
                className="btn transaction withdraw-btn"
              >
                Withdraw
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Withdraw;
