import React from "react";
import { Link } from "react-router-dom";
import MainLayout from "../components/layout";

export default function PrivacyPolicy() {
  return (
    <MainLayout>
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Privacy Policy</h1>

              <p>
                vTrading.biz respects our users’ right to privacy. We do not
                collect any personal data from you unless it is strictly
                necessary (e.g. for broker complaints).
              </p>

              <p>
                By using our website and reading out articles, you abide to the
                privacy policy here on this page.
              </p>
              <h2>Users Privacy</h2>
              <p>
                We do not collecting personal information from any of our users,
                except in cases where this is strictly necessary. We do not pass
                on or sell your personal information to any third parties or
                operators. If you would like to contact us or provide a
                complaint, can do so through our{" "}
                <Link to="/contact-us">contact page</Link> here.
              </p>
              <h2>Cookie Tracking Software</h2>

              <p>
                Our website uses a number of different types of cookies which
                are necessary for the functioning and monetizing of our website.
              </p>
              <p>
                Many of our links to third party websites use affiliate tracking
                links. This means that when you click on some of our links to
                binary options brokers, a harmless cookie will be installed on
                your computer for a period of 30-60 days. These tracking cookies
                are necessary for the financial compensation of our website and
                tracking users to different websites. The cookies themselves are
                not served by our website and we do not collect any personal
                information from them.
              </p>

              <p>
                In addition to third party tracking cookies, our website also
                uses Analytics cookies to analyze website behavior and traffic
                on our site. This is strictly necessary for monitoring our
                traffic (which we need proof of in order to negotiate media
                deals and sell banners), finding our most popular articles and
                seeing where our traffic is coming form.
              </p>

              <h2>Affiliate Earnings Disclaimer</h2>

              <p>
                vTrading.biz may receive revenue through affiliate marketing
                deals with binary options brokers. Website users may therefore
                be tracked to third party websites through harmless tracking
                cookies.
              </p>

              <h2>Changes to our Privacy Policy</h2>
              <p>
                Please note that we respect the right to make changes or updates
                to our privacy policy at any time without notifying you. It is
                your responsibility to check our privacy policy for future
                changes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
