import React from "react";
import MainLayout from "../components/layout";
import Account from "../components/account";

export default function Wallet() {
  return (
    <MainLayout>
      <Account tab={"wallet"} />
    </MainLayout>
  );
}
