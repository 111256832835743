import { countries } from "assets/countries";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import MainLayout from "../components/layout";
import { UserContext } from "../context/UserContext";
import { SignupAPI } from "api/common/api";

export default function Signup() {
  const { setAuth } = useContext(UserContext);
  let history = useHistory();
  let location = useLocation();

  const [values, setValues] = useState({
    fullName: "",
    email: "",
    password: "",
    country: "",
    confirmPassword: "",
    referralCode: "",
    checked: false,
  });

  useEffect(() => {
    // Extract referral code from URL query parameters
    const queryParams = new URLSearchParams(location.search);
    const referralCode = queryParams.get("referral");
    if (referralCode) {
      setValues((prevValues) => ({ ...prevValues, referralCode }));
    }
  }, [location.search]);

  const handCheckbutton = () => {
    setValues({ ...values, checked: !values.checked });
  };

  const handleValidation = () => {
    const { password, confirmPassword, fullName, email, country, checked } =
      values;

    const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (!checked) {
      toast.error("Please check the Terms & Conditions.");
    } else if (fullName.length < 4) {
      toast.error("The Full Name should be greater than 3 characters");
    } else if (!country) {
      toast.error("Country is required");
    } else if (!email.match(mailformat)) {
      toast.error("Please enter a valid mail");
    } else if (password.length < 6) {
      toast.error("Password should be equal or greater 6 characters");
    } else if (password !== confirmPassword) {
      toast.error(
        "The password and the password confimartion are not the same"
      );
    } else {
      return true;
    }
    return false;
  };

  const handleChange = (e: any) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (handleValidation()) {
      const { fullName, email, password, referralCode, country } = values;
      const submitValues = { fullName, email, password, country, referralCode };
      const { response, errors } = await SignupAPI(submitValues);
      if (errors) {
        errors.map((err: any) => toast.error(err.message));
        return;
      }
      if (!response || !response.success) {
        toast.error("Server is not responding correctly. Please Try again.");
        return;
      }
      localStorage.setItem("vTrading-user", JSON.stringify(response));
      setAuth({
        signedIn: true,
        auth_token: response.auth_token,
        user: response.user,
      });
      history.push("/exchange");
    }
  };

  return (
    <MainLayout>
      <div className="vh-100 d-flex justify-content-center">
        <div className="form-access my-auto">
          <form>
            <span>Create Account</span>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Full Name"
                name="fullName"
                value={values.fullName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Email Address"
                name="email"
                value={values.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <select
                className="form-control"
                name="country"
                value={values.country}
                onChange={handleChange}
                required
              >
                <option value="" disabled selected>
                  Country
                </option>
                {countries.map((country, idx) => (
                  <option key={idx} value={country}>
                    {country}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                name="password"
                value={values.password}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                placeholder="Confirm Password"
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Enter referral code"
                name="referralCode"
                value={values.referralCode}
                onChange={handleChange}
                required
              />
            </div>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="form-checkbox"
                onChange={handCheckbutton}
                required
              />
              <label className="custom-control-label" htmlFor="form-checkbox">
                I agree to the{" "}
                <Link to="/terms-and-conditions">Terms & Conditions</Link>
              </label>
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Create Account
            </button>
          </form>
          <h2>
            Already have an account?
            <Link to="/login"> Sign in here</Link>
          </h2>
        </div>
      </div>
    </MainLayout>
  );
}
