import React from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Deposit from "./Deposit";
import InviteUser from "./InviteUser";
import GeneralInfo from "./profile/GeneralInfo";
import SecurityInfo from "./profile/SecurityInfo";
import Settings from "./Settings";
import Wallet from "./Wallet";
import Withdraw from "./Withdraw";
import Transactions from "./Transactions";

const Account = ({ tab }: any) => {
  let history = useHistory();
  return (
    <div className="settings mtb15" style={{ minHeight: "60vh" }}>
      <div className="container-fluid">
        <Tab.Container defaultActiveKey={tab}>
          <Row>
            <Col lg={3}>
              <Nav variant="pills" className="settings-nav">
                <Nav.Item>
                  <Nav.Link
                    onClick={() => history.push("/profile")}
                    eventKey="profile"
                  >
                    Profile
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    onClick={() => history.push("/wallet")}
                    eventKey="wallet"
                  >
                    Wallet
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    onClick={() => history.push("/deposit")}
                    eventKey="deposit"
                  >
                    Deposit
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    onClick={() => history.push("/withdraw")}
                    eventKey="withdraw"
                  >
                    Withdraw
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    onClick={() => history.push("/transactions")}
                    eventKey="transactions"
                  >
                    Transactions
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    onClick={() => history.push("/invite-user")}
                    eventKey="invite-user"
                  >
                    Invite a friend
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col lg={9}>
              <Tab.Content>
                <Tab.Pane eventKey="profile">
                  <div className="card">
                    <GeneralInfo />
                  </div>
                  <div className="card">
                    <SecurityInfo />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="wallet">
                  <Wallet />
                </Tab.Pane>
                <Tab.Pane eventKey="deposit">
                  <Deposit />
                </Tab.Pane>
                <Tab.Pane eventKey="withdraw">
                  <Withdraw />
                </Tab.Pane>
                <Tab.Pane eventKey="invite-user">
                  <InviteUser />
                </Tab.Pane>
                <Tab.Pane eventKey="transactions">
                  <Transactions />
                </Tab.Pane>
                {/* <Tab.Pane eventKey="settings">
                  <Settings />
                </Tab.Pane> */}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
};

export default Account;
