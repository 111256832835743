import { UserContext } from "context/UserContext";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  GetInvitedUsers,
  GetReferralRewardAPI,
  GetReferralSettingsAPI,
  User,
} from "api/common/api";

import Joyride, { Step, CallBackProps, STATUS } from "react-joyride";
import ReferralSection from "./referral/RefferalSection";
import ReffralTabs from "./referral/RefferalTabs";

const InviteUser = () => {
  const { auth } = useContext(UserContext);
  const [invitedUsers, setInvitedUsers] = useState<User[]>([]);
  const [referralReward, setReferralReward] = useState<any>([]);
  const [settings, setSettings] = useState<any>([]);
  const [runTour, setRunTour] = useState(true);

  const referralCode = auth.user.referralCode;
  const userLevel = auth.user.level;

  const levelImages: any = {
    0: "/vip1.png",
    1: "/vip1.png",
    2: "/vip2.png",
    3: "/vip3.png",
    4: "/vip4.png",
    5: "/vip5.png",
  };

  const getSettings = async () => {
    const { response, errors } = await GetReferralSettingsAPI({
      auth_token: auth.auth_token,
    });
    if (errors) {
      errors.map((err: any) => toast.error(err.message));
      return;
    }
    setSettings(response);
  };

  const getInvitedUser = async () => {
    const { response, errors } = await GetInvitedUsers({
      auth_token: auth.auth_token,
    });

    if (errors) {
      errors.map((err: any) => toast.error(err.message));
      return;
    }

    if (!response || !response.success) {
      toast.error("Server is not responding correctly.");
      return;
    }

    setInvitedUsers(response.invitedUsers);
  };

  const getReferralReward = async () => {
    const { response, errors } = await GetReferralRewardAPI({
      auth_token: auth.auth_token,
    });

    if (errors) {
      errors.map((err: any) => toast.error(err.message));
      return;
    }

    if (!response || !response.success) {
      toast.error("Server is not responding correctly.");
      return;
    }

    setReferralReward(response);
  };

  useEffect(() => {
    getInvitedUser();
    getReferralReward();
    getSettings();
  }, []);

  const referralBalance = referralReward ? referralReward.referralBalance : 0;
  const rebateBalance = referralReward ? referralReward.rebateBalance : 0;

  // Function to get referral reward percentage for the current user level
  const getReferralRewardPercentage = (level: number) => {
    const levelKey = `level${level}Reward`;
    const setting = settings.find((s: any) => s.key === levelKey);
    return setting ? setting.value : 0;
  };

  // Function to get rebate direct reward percentage
  const getRebateDirectRewardPercentage = () => {
    const setting = settings.find((s: any) => s.key === "directRebate");
    return setting ? setting.value : 0;
  };

  // Function to get rebate indirect reward percentage
  const getRebateIndirectRewardPercentage = () => {
    const setting = settings.find((s: any) => s.key === "indirectRebate");
    return setting ? setting.value : 0;
  };

  const steps: Step[] = [
    {
      target: ".invite-friends",
      content: "Here you can invite your friends and earn rewards.",
    },
    {
      target: ".reward-info",
      content: "This section shows your referral and rebate rewards.",
    },
    {
      target: ".referral-code",
      content: "Share this referral code with your friends.",
    },
  ];

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type } = data;
    const finishedStatuses: (typeof STATUS)[keyof typeof STATUS][] = [
      STATUS.FINISHED,
      STATUS.SKIPPED,
    ];
    if (finishedStatuses.includes(status)) {
      setRunTour(false);
    }
  };

  return (
    <div className="flex flex-col gap-10 py-10 ">
      <Joyride
        steps={steps}
        continuous
        showSkipButton
        run={runTour}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />

      <div className="flex flex-col gap-2 text-xl sm:text-2xl lg:text-4xl text-white items-center justify-center text-center">
        <p className="font-semibold">Invite friends</p>
        <p className="font-bold">
          Enjoy Up to <span className="text-[#007bff]">40%</span> Referral
          Reward
        </p>
        <p className="text-xs sm:text-sm lg:text-base text-gray-400">
          Invite friends to register and trade to share up to 40% referral
          reward. Seize this great earning opportunity with your friends!
        </p>
        <div className="my-2 invite-friends"></div>
      </div>
      <div className="!border border-[#27282c] bg-[#181a1f] rounded-2xl py-3 px-4 w-full flex flex-col reward-info">
        <div className="w-full flex justify-between items-center text-white p-4">
          <div className="flex items-center justify-center gap-2">
            <p className="text-lg flex items-center gap-2">
              <img
                src={levelImages[userLevel] || "/default.png"}
                alt="Level Icon"
                className="w-8 h-8 sm:w-12 sm:h-12"
              />{" "}
              Level {userLevel}
            </p>
            <HoverInfo settings={settings} /> {/* Pass settings to HoverInfo */}
          </div>
        </div>
        <div className="bg-[#121417] rounded-2xl flex flex-col xl:flex-row justify-between xl:items-center p-4 gap-4 xl:gap-10 w-full">
          <div className="flex flex-col xl:flex-row items-start xl:items-center  xl:gap-20 xl:w-2/3 2xl:w-full ">
            <div className="flex flex-col gap-2 text-white">
              <div className="text-sm text-gray-500">I receive</div>
              <div className="text-[#f38b2c] text-2xl font-bold">
                {getReferralRewardPercentage(userLevel)}%
              </div>
            </div>
            <div className="flex flex-col gap-2 text-white">
              <div className="text-sm text-gray-500">Rebate Direct Reward</div>
              <div className="text-white text-2xl font-bold">
                {getRebateDirectRewardPercentage()}%
              </div>
            </div>
            <div className="flex flex-col gap-2 text-white">
              <div className="text-sm text-gray-500">
                Rebate Indirect Reward
              </div>
              <div className="text-white text-2xl font-bold">
                {getRebateIndirectRewardPercentage()}%
              </div>
            </div>
          </div>
          <div className="w-full xl:w-1/3 2xl:w-full text-white referral-code">
            <ReferralSection referralCode={referralCode} />
          </div>
        </div>
      </div>
      <div className="w-full">
        <ReffralTabs
          referralBalance={referralBalance}
          invitedUser={invitedUsers}
          referralReward={referralReward}
          rebateBalance={rebateBalance}
          settings={settings}
        />
      </div>
    </div>
  );
};

export default InviteUser;

const HoverInfo = ({ settings }: any) => {
  const [isHovered, setIsHovered] = useState(false);

  const getLevelRewardPercentage = (level: number) => {
    const levelKey = `level${level}Reward`;
    const setting = settings.find((s: any) => s.key === levelKey);
    return setting ? setting.value : 0;
  };

  return (
    <div className="relative inline-block text-left">
      <div
        className="flex items-center gap-1"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <button className="bg-gray-700 text-white rounded-full p-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            width={20}
            height={20}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
            />
          </svg>
        </button>
      </div>
      {isHovered && (
        <div className="absolute z-10 mt-2 w-56 rounded-md shadow-lg bg-[#121417] ring-1 ring-black ring-opacity-5">
          <div className="p-4">
            <p className="text-gray-500 text-sm mb-2">VIP Level</p>
            <ul>
              {[0, 1, 2, 3, 4, 5].map((level) => (
                <li
                  key={level}
                  className="text-white py-1 flex justify-between"
                >
                  <span>VIP{level}</span>
                  <span>{getLevelRewardPercentage(level)}%</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
