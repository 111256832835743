import { AuthenticatedPropsGenerator, Response } from "../_types";
import { GetUserDepositsResponse } from "../_types/requests/user/transactions";
import { getAuthenticatedApi } from "../apiGenerator";

export const GetUserDepositsAPI = ({
  auth_token,
}: AuthenticatedPropsGenerator<{}>): Promise<
  Response<GetUserDepositsResponse>
> =>
  getAuthenticatedApi<GetUserDepositsResponse>({
    url: `/user/transactions`,
    auth_token,
  });
