import React from "react";
import { Switch, Route } from "react-router-dom";
import Exchange from "../pages/exchange";
import Profile from "./profile";
import Wallet from "./wallet";
import Settings from "./settings";
import Login from "./login";
import Reset from "./reset";
import TermsAndConditions from "./terms-and-conditions";
import Signup from "./signup";
import Notfound from "./notfound";
import Home from "./home";
import AboutUs from "./about-us";
import PrivacyPolicy from "./privacy-policy";
import ContactUs from "./contact-us";
import Withdraw from "./withdraw";
import Deposit from "./deposit";
import ProtectedRoute from "../utils/ProtectedRoute";
import InviteUser from "./invite-user";
import Transactions from "./transactions";

export default function index() {
  return (
    <>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/terms-and-conditions">
          <TermsAndConditions />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/about-us">
          <AboutUs />
        </Route>
        <Route path="/contact-us">
          <ContactUs />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/signup">
          <Signup />
        </Route>
        <ProtectedRoute path="/exchange">
          <Exchange />
        </ProtectedRoute>
        <ProtectedRoute path="/profile">
          <Profile />
        </ProtectedRoute>
        <ProtectedRoute path="/invite-user">
          <InviteUser />
        </ProtectedRoute>
        <ProtectedRoute path="/transactions">
          <Transactions />
        </ProtectedRoute>
        <ProtectedRoute path="/wallet">
          <Wallet />
        </ProtectedRoute>
        <ProtectedRoute path="/withdraw">
          <Withdraw />
        </ProtectedRoute>
        <ProtectedRoute path="/deposit">
          <Deposit />
        </ProtectedRoute>
        <ProtectedRoute path="/settings">
          <Settings />
        </ProtectedRoute>
        <ProtectedRoute path="/reset">
          <Reset />
        </ProtectedRoute>

        <Route path="*">
          <Notfound />
        </Route>
      </Switch>
    </>
  );
}
