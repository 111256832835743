import { UserContext } from "context/UserContext";
import React, { useContext } from "react";

const VipLevel = ({ settings }: any) => {
  const { auth } = useContext(UserContext);
  const currentLevel = auth.user.level;
  // Function to get the required active friends for the next level
  const getNextLevelRequirements = (currentLevel: number) => {
    const nextLevel = currentLevel + 1;
    const levelKey = `level${nextLevel}Required`;
    const setting = settings.find((s: any) => s.key === levelKey);
    return setting ? setting.value : null;
  };

  // Function to get the referral reward percentage for a level
  const getReferralRewardPercentage = (level: number) => {
    const levelKey = `level${level}Reward`;
    const setting = settings.find((s: any) => s.key === levelKey);
    return setting ? setting.value : "N/A";
  };

  // Function to get the direct rebate percentage for a level
  const getDirectRebatePercentage = () => {
    const setting = settings.find((s: any) => s.key === "directRebate");
    return setting ? setting.value : "N/A";
  };

  // Function to get the indirect rebate percentage for a level
  const getIndirectRebatePercentage = () => {
    const setting = settings.find((s: any) => s.key === "indirectRebate");
    return setting ? setting.value : "N/A";
  };

  const nextLevelRequirements = getNextLevelRequirements(currentLevel);

  return (
    <div className="text-white mb-14">
      <div
        className="bg-[#464a52] rounded-t-lg p-6 text-center relative overflow-hidden "
        style={{
          backgroundImage: `url('/bg-pc-night.png')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          className="absolute bottom-2 left-2 lg:left-4 lg:top-2 sm:left-10 sm:top-10 w-12 h-12 sm:w-20 sm:h-20 bg-cover bg-no-repeat"
          style={{ backgroundImage: "url('/vip.png')" }}
        ></div>
        <div className="relative z-10">
          <h1 className="text-xl sm:text-2xl font-bold mb-2">
            You only need{" "}
            <span className="text-yellow-500">
              {nextLevelRequirements} active friends
            </span>{" "}
            to become{" "}
            <span className="text-yellow-500">VIP {currentLevel + 1}</span>
          </h1>
          <p className="text-gray-400 mb-4 text-sm sm:text-base">
            Friendly entry plus great benefits, enjoy multiple VIP exclusives
          </p>
        </div>
      </div>
      <div className="bg-[#464a52] rounded-b-lg p-4 sm:p-6 overflow-x-scroll min-w-full">
        <h2 className="text-lg sm:text-xl font-bold mb-4">About VIP Level</h2>
        <div className="min-w-[600px] overflow-x-scroll">
          <div className="flex flex-row text-white py-3 px-4">
            <div className="w-1/4 font-bold">VIP Lv</div>
            <div className="w-1/4 font-bold">Referral Reward</div>
            <div className="w-1/4 font-bold">Rebate Reward (Direct)</div>
            <div className="w-1/4 font-bold">Rebate Reward (Indirect)</div>
          </div>
          {[
            { level: 1, image: "/vip1.png" },
            { level: 2, image: "/vip2.png" },
            { level: 3, image: "/vip3.png" },
            { level: 4, image: "/vip4.png" },
            { level: 5, image: "/vip5.png" },
          ].map((vip, index) => (
            <div
              key={index}
              className="flex flex-row items-center text-white py-3 px-4 border-b border-gray-600"
            >
              <div className="w-1/4 flex items-center gap-2">
                <img
                  src={vip.image}
                  alt={`${vip.level} icon`}
                  className="w-8 h-8 sm:w-12 sm:h-12"
                />
                <span>VIP {vip.level}</span>
              </div>
              <div className="w-1/4 text-left">
                {getReferralRewardPercentage(vip.level)}%
              </div>
              <div className="w-1/4 text-left">
                {getDirectRebatePercentage()}%
              </div>
              <div className="w-1/4 text-left">
                {getIndirectRebatePercentage()}%
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VipLevel;
