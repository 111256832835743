import React from "react";
import MainLayout from "../components/layout";
import Account from "../components/account";

export default function Deposit() {
  return (
    <MainLayout>
      <Account tab={"deposit"} />
    </MainLayout>
  );
}
