import {
  getAuthenticatedApi,
  postAuthenticatedApi,
  putAuthenticatedApi,
} from "../apiGenerator";
import {
  AuthenticatedPropsGenerator,
  EvaluateBetRequest,
  EvaluateBetResponse,
  GetInvitedUsersResponse,
  GetUserBetsResponse,
  PlaceBetRequest,
  PlaceBetResponse,
  RequestWithdrawalRequest,
  RequestWithdrawalResponse,
  Response,
  UpdatePasswordRequest,
  UpdatePasswordResponse,
  UpdateUserRequest,
  UpdateUserResponse,
  VerifyDepositRequest,
  VerifyDepositResponse,
} from "../_types";

// ? POST
export const RequestWithdrawalAPI = ({
  auth_token,
  amount,
  withdrawalCryptoWallet,
}: AuthenticatedPropsGenerator<RequestWithdrawalRequest>): Promise<
  Response<RequestWithdrawalResponse>
> =>
  postAuthenticatedApi<RequestWithdrawalRequest, RequestWithdrawalResponse>({
    url: `/user/account/request-withdrawal`,
    body: { amount, withdrawalCryptoWallet },
    auth_token,
  });

export const VerifyDepositAPI = ({
  auth_token,
  hash,
}: AuthenticatedPropsGenerator<VerifyDepositRequest>): Promise<
  Response<VerifyDepositResponse>
> =>
  postAuthenticatedApi<VerifyDepositRequest, VerifyDepositResponse>({
    url: `/platform/verify-deposit`,
    body: { hash },
    auth_token,
  });

export const PlaceBetAPI = ({
  auth_token,
  ...placeBetObj
}: AuthenticatedPropsGenerator<PlaceBetRequest>): Promise<
  Response<PlaceBetResponse>
> =>
  postAuthenticatedApi<PlaceBetRequest, PlaceBetResponse>({
    url: `/user/account/place-bet`,
    body: placeBetObj,
    auth_token,
  });

export const EvaluateBetAPI = ({
  auth_token,
  betId,
  ...evaluateBetObj
}: AuthenticatedPropsGenerator<
  EvaluateBetRequest & { betId: string }
>): Promise<Response<EvaluateBetResponse>> =>
  postAuthenticatedApi<EvaluateBetRequest, EvaluateBetResponse>({
    url: `/user/account/evaluate-bet/${betId}`,
    body: evaluateBetObj,
    auth_token,
  });

// ? GET
export const GetUserBetsAPI = ({
  auth_token,
}: AuthenticatedPropsGenerator<{}>): Promise<Response<GetUserBetsResponse>> =>
  getAuthenticatedApi<GetUserBetsResponse>({
    url: `/user/account/bets`,
    auth_token,
  });

export const GetInvitedUsers = ({
  auth_token,
}: AuthenticatedPropsGenerator<{}>): Promise<
  Response<GetInvitedUsersResponse>
> =>
  getAuthenticatedApi<GetInvitedUsersResponse>({
    url: `/user/account/invited-users`,
    auth_token,
  });

// ? PUT
export const UpdateUserAPI = ({
  auth_token,
  ...updateUserObj
}: AuthenticatedPropsGenerator<UpdateUserRequest>): Promise<
  Response<UpdateUserResponse>
> =>
  putAuthenticatedApi<UpdateUserRequest, UpdateUserResponse>({
    url: `/user/account/update-user`,
    body: updateUserObj,
    auth_token,
  });

export const UpdatePasswordAPI = ({
  auth_token,
  ...updatePasswordObj
}: AuthenticatedPropsGenerator<UpdatePasswordRequest>): Promise<
  Response<UpdatePasswordResponse>
> =>
  putAuthenticatedApi<UpdatePasswordRequest, UpdatePasswordResponse>({
    url: `/user/account/update-password`,
    body: updatePasswordObj,
    auth_token,
  });
