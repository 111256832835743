import React from "react";
import { Link } from "react-router-dom";
import MainLayout from "../components/layout";

export default function TermsAndConditions() {
  return (
    <MainLayout>
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="page-title">Terms and Conditions</h1>

              <p>
                vTrading.biz is a binary options information and comparison
                website. We are not a binary options broker and we do not accept
                trades or wagers or any kind. Our website is not regulated by a
                financial body such as the FSA or Gaming Authority of Malta. As
                such, our website should be treated as for entertainment
                purposes only.
              </p>

              <p>
                By using our website and reading our article, you accept the
                terms and conditions laid out on this page.
              </p>
              <h2>Editiors and content:</h2>
              <ol>
                <li>
                  <p>
                    The content of this website is copyright to vTrading.biz.
                    Any use of our content, articles or trading strategies
                    without our express permission shall be treated as theft
                    under copyright law.
                  </p>
                </li>
                <li>
                  <p>
                    The views expressed in contributor articles or on the forum
                    are expressed by those contributors and do not necessarily
                    reflect the views of BinaryOptions.net. It is your
                    responsibility to check the validity of information on our
                    site before you sign up to brokers or deposit money online.
                  </p>
                </li>
                <li>
                  <p>
                    We are not responsible for any money lost through our
                    website or another third party operator
                  </p>
                </li>
                <li>
                  <p>
                    It is your legal responsibility to check the legality of
                    trading binary options and age requirements in your
                    jurisdiction.
                  </p>
                </li>
                <li>
                  <p>
                    We may occasionally link out to other websites in our
                    articles. These links are published under the purview of our
                    editors.
                  </p>
                </li>
              </ol>
              <h2>USA Regulation Notice:</h2>

              <p>
                Please note if you are from the USA: some binary options
                companies are not regulated within the United States. These
                companies are not supervised, connected or affiliated with any
                of the regulatory agencies such as the Commodity Futures Trading
                Commission (CFTC), National Futures Association (NFA),
                Securities and Exchange Commission (SEC) or the Financial
                Industry Regulatory Authority (FINRA). We warn US citizens of
                the dangers of trading with such entities and strongly advise
                that they take legal advice on this in the US.
              </p>

              <h2>3rd Party Sites</h2>

              <p>
                Please note that when you click on a link to a third-party
                website you enter into a separate contract with that website
                which is not covered by our terms.
              </p>

              <h2>Affiliate Earnings Disclaimer</h2>

              <p>
                For full disclosure, some of the links on our websites will
                include tracking links which install a harmless cookie on your
                website for a period of 30-60 days. We may be financially
                compensated for any traffic we send through e.g. to binary
                options brokers. From time to time, we may also sell fixed media
                deals to binary options brokers, which are independent of the
                editor’s recommendations.
              </p>
              <h2>Privacy Policy</h2>
              <p>
                vTrading.biz respects your privacy. For more information, you
                can read our <Link to="/privacy-policy">privacy policy</Link> on
                this page.
              </p>

              <h2>Changes to Our Terms</h2>

              <p>
                We reserve the right to make changes to our terms and conditions
                at any time without notifying you. It is recommended that you
                revisit this page in the future to check any changes to our
                terms.
              </p>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
