import React from "react";
import Bank from "../../assets/img/landing/bank.svg";
import User from "../../assets/img/landing/user.svg";
import Trade from "../../assets/img/landing/trade.svg";

const LandingStart = () => {
  return (
    <div className="landing-feature landing-start">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2>Start Trading in 3 Steps</h2>
          </div>
          <div className="col-md-4">
            <div className="landing-feature-item">
              <img src={User} alt="" />
              <span>1</span>
              <h3>Register</h3>
              <p>Open an account for FREE in just 4 easy steps .</p>
              <br />
            </div>
          </div>
          <div className="col-md-4">
            <div className="landing-feature-item">
              <img src={Bank} alt="" />
              <span>2</span>
              <h3>Deposit</h3>
              <p>Deposit USDT (TRC20) to the given deposit address.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="landing-feature-item">
              <img src={Trade} alt="" />
              <span>3</span>
              <h3>Trade & Win</h3>
              <p>Start trading with us and keep wining and withdrawing.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingStart;
