import React from "react";
import { MiniChart } from "react-tradingview-embed";

const SymbolOverviewCard = (props: any) => {
  return (
    <div className="tradingview-widget-container">
      <div className="tradingview-widget-container__widget"></div>
      <MiniChart
        widgetProps={{
          symbol: props.symbol,
          width: "100%",
          height: 220,
          locale: "en",
          dateRange: "12M",
          colorTheme: "dark",
          trendLineColor: "rgba(41, 98, 255, 1)",
          underLineColor: "rgba(41, 98, 255, 0.3)",
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ symbol: any; width: string; height: number... Remove this comment to see the full error message
          underLineBottomColor: "rgba(41, 98, 255, 0)",
          isTransparent: false,
          autosize: false,
          largeChartUrl: "",
        }}
      />
    </div>
  );
};

export default SymbolOverviewCard;
