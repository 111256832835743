import React from "react";
import { TechnicalAnalysis } from "react-tradingview-embed";

const Hero = () => {
  return (
    <div className="landing-hero">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h2>Simple. Reliable. Virtual Trading</h2>
            <p>
              Best binary options trading platform - now trade on
              cryptocurrencies 24/7
            </p>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your Email"
                aria-label="Enter Your Email"
                aria-describedby="button-addon2"
              />
              <div className="input-group-append">
                <button
                  className="btn btn-primary"
                  type="button"
                  id="button-addon2"
                >
                  Get Started
                </button>
              </div>
            </div>
          </div>
          <div className="offset-md-1 col-md-5">
            <div className="tradingview-widget-container tradingview-tecnical-analys">
              <div className="tradingview-widget-container__widget"></div>
              <TechnicalAnalysis
                widgetProps={{
                  interval: "1m",
                  width: "100%",
                  isTransparent: false,
                  height: 450,
                  symbol: "BINANCE:BTCUSDT",
                  showIntervalTabs: true,
                  locale: "en",
                  colorTheme: "dark",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
