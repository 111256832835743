import React from "react";
import SymbolOverviewCard from "./SymbolOverviewCard";

const SymbolSection = () => {
  return (
    <div className="landing-feature landing-coin-price bt-none">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2>
              Check your favorite coin price <br /> within a glance
            </h2>
          </div>
          <div className="col-md-3 mb30">
            <SymbolOverviewCard symbol={"BINANCE:BTCUSDT"} />
          </div>
          <div className="col-md-3 mb30">
            <SymbolOverviewCard symbol={"BINANCE:ETHUSDT"} />
          </div>
          <div className="col-md-3 mb30">
            <SymbolOverviewCard symbol={"BINANCE:XRPUSDT"} />
          </div>
          <div className="col-md-3 mb30">
            <SymbolOverviewCard symbol={"BINANCE:BNBUSDT"} />
          </div>
          <div className="col-md-3 mb30">
            <SymbolOverviewCard symbol={"BINANCE:ADAUSDT"} />
          </div>
          <div className="col-md-3 mb30">
            <SymbolOverviewCard symbol={"BINANCE:DOGEUSDT"} />
          </div>
          <div className="col-md-3 mb30">
            <SymbolOverviewCard symbol={"BINANCE:DOTUSDT"} />
          </div>
          <div className="col-md-3 mb30">
            <SymbolOverviewCard symbol={"BINANCE:SOLUSDT"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SymbolSection;
