import React, { useContext, useState } from "react";
import Higher from "../../assets/img/higher.png";
import Lower from "../../assets/img/lower.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsd, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import Countdown from "react-countdown";

import { UserContext } from "../../context/UserContext";
import { toast } from "react-toastify";
import { Bet, BetTypes, PlaceBetAPI } from "api/common/api";

interface Props {
  activeBets: Bet[];
  updateLog: () => void;
}

export default function Betting({ activeBets, updateLog }: Props) {
  const { auth, updateUser } = useContext(UserContext);
  const [inputValues, setInputValues] = useState({
    betAmount: 5,
    timer: 1,
  });
  const [disable, setDisable] = useState(false);

  const increaseAmount = (e: any) => {
    setInputValues({ ...inputValues, betAmount: inputValues.betAmount + 1 });
  };

  const decreaseAmount = (e: any) => {
    setInputValues({
      ...inputValues,
      betAmount: Math.max(inputValues.betAmount - 1, 1),
    });
  };

  const increaseTime = (e: any) => {
    setInputValues({
      ...inputValues,
      timer: Math.min(inputValues.timer + 1, 5),
    });
  };

  const decreaseTime = (e: any) => {
    setInputValues({
      ...inputValues,
      timer: Math.max(inputValues.timer - 1, 1),
    });
  };

  const betButtonHandler = async (e: any) => {
    console.log(e.target.alt);
    if (disable) {
      return;
    }

    setDisable((_) => true);

    const { response, errors } = await PlaceBetAPI({
      auth_token: auth.auth_token,
      betAmount: inputValues.betAmount,
      timer: inputValues.timer,
      value: 0,
      prediction: e.target.alt,
    });

    setDisable((_) => false);

    if (errors) {
      errors.map((err: any) => toast.error(err.message));
      return;
    }

    if (!response || !response.success) {
      toast.error("Server is not responding correctly. Please Try again.");
      return;
    }
    updateLog();
    updateUser();
    const { betAmount } = response.bet;
    toast.success(`Your $${betAmount} bet on ${e.target.name} was placed`);
  };

  // const evaluateBet = useCallback(
  //   async (bet: Bet) => {
  //     const { errors, response } = await EvaluateBetAPI({
  //       auth_token: auth.auth_token,
  //       betId: bet.id,
  //       value: 0,
  //     });

  //     if (errors) {
  //       errors.map((err) => toast.error(err.message));
  //     }

  //     if (!response) return;

  //     console.log(response.bet);

  //     const { result, profit } = response.bet;

  //     if (result === "win")
  //       toast.success(`You have just won $${profit} on your last bet`);
  //     if (result === "loss") toast.success(`You have lost your last bet`);
  //   },
  //   [auth.auth_token]
  // );

  return (
    <div className="market-bet">
      <div className="account-balance">
        <h3>
          Revenue:{" "}
          <span className="green">${auth.user.revenue.toFixed(2)}</span>
        </h3>
        <h3>
          Balance:{" "}
          <span className="green">${auth.user.wallet.balance.toFixed(2)}</span>
        </h3>
      </div>
      <div className="betting-section">
        <div className="bet left">
          <div className="btn img-wrapper" onClick={betButtonHandler}>
            <img src={Lower} alt={BetTypes.low} />
          </div>
          <div className="input-area">
            <span className="input-title">Amount</span>
            <span className="input-data">${inputValues.betAmount}</span>
            <div className="input-buttons">
              <span onClick={increaseAmount} className="plus">
                <FontAwesomeIcon icon={faPlus} />
              </span>
              <span className="curr">
                <FontAwesomeIcon icon={faUsd} />
              </span>
              <span onClick={decreaseAmount} className="minus">
                <FontAwesomeIcon icon={faMinus} />
              </span>
            </div>
          </div>
        </div>
        <div className="bet right">
          <div className="input-area">
            <div className="input-title">Time</div>
            <span className="input-data">00:0{inputValues.timer}:00</span>
            <div className="input-buttons">
              <span onClick={increaseTime} className="plus">
                <FontAwesomeIcon icon={faPlus} />
              </span>
              <span className="curr">
                <FontAwesomeIcon icon={faClock} />
              </span>
              <span onClick={decreaseTime} className="minus">
                <FontAwesomeIcon icon={faMinus} />
              </span>
            </div>
          </div>
          <div className="btn img-wrapper" onClick={betButtonHandler}>
            <img src={Higher} alt={BetTypes.high} />
          </div>
        </div>
      </div>
      <div className="multiple-timers">
        {activeBets.map((bet) => (
          <Countdown
            key={bet.id}
            date={Date.parse(bet.createdAt) + bet.timer * 60000}
            onComplete={updateLog}
            renderer={({ hours, minutes, seconds, completed }) => {
              if (completed) {
                return null;
              } else {
                let color = bet.prediction === "high" ? "green" : "red";
                return (
                  <div className="cooldown">
                    <div className="blockContainer">
                      <div className="block empty-block"></div>
                      <div className="block active-timer">
                        <h3>
                          {String(hours).padStart(2, "0")}:
                          {String(minutes).padStart(2, "0")}:
                          {String(seconds).padStart(2, "0")}
                        </h3>
                      </div>
                    </div>
                    <div className="blockContainer">
                      <div className="block type-price">
                        <h3>
                          <span className={color}> {bet.prediction}er</span> |
                          <span> ${bet.valueAtPlacing} </span>
                        </h3>
                      </div>
                      <div className="block empty-block"></div>
                    </div>
                  </div>
                );
              }
            }}
          />
        ))}
      </div>
    </div>
  );
}
