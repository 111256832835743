import Logo from "../../assets/img/logo.svg";

import FooterRisk from "../../assets/img/landing/footer-risk.png";
import Store from "../../assets/img/landing/google-apple-store.png";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer className="landing-footer-two">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <img src={Logo} alt="" />
            <p>
              vTrading is one of the fastest growing online trading brands in
              the world. Voted the best mobile trading platform, we have now
              expanded our offerings to to the world. Get on to it and start
              learning to trade.
            </p>
            <ul className="social-icon">
              <li>
                <a href="#!">
                  <i className="icon ion-logo-facebook"></i>
                </a>
              </li>
              <li>
                <a href="#!">
                  <i className="icon ion-logo-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#!">
                  <i className="icon ion-logo-linkedin"></i>
                </a>
              </li>
              <li>
                <a href="#!">
                  <i className="icon ion-logo-pinterest"></i>
                </a>
              </li>
              <li>
                <a href="#!">
                  <i className="icon ion-logo-github"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-2 company">
            <h3>Company</h3>
            <ul>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
              <li>
                <a href="#!">
                  <div className="image-container">
                    <img src={Store} alt="google play and app store links" />
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-7 risks">
            <img src={FooterRisk} alt="Risks" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
