import React, { useContext, useState } from "react";
import { UserContext } from "../../../context/UserContext";
import { toast } from "react-toastify";
import { UpdateUserAPI } from "api/common/api";

const GeneralInfo = () => {
  const { auth, updateUser } = useContext(UserContext);
  const initialUserValues = {
    fullName: "",
    cryptoWallet: "",
    email: "",
    phone: "",
  };
  const [userValues, setUserValues] = useState(initialUserValues);
  const handleUserChange = (e: any) => {
    setUserValues({ ...userValues, [e.target.name]: e.target.value });
  };
  const handleUserValidation = () => {
    const { fullName, email, phone, cryptoWallet } = userValues;
    const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (!(fullName || email || phone || cryptoWallet)) {
      toast.error("Please enter the infos to be updated");
      return false;
    }
    if (email && !email.match(mailformat)) {
      toast.error("Please enter a valid mail");
      return false;
    }
    if (fullName && fullName.length < 4) {
      toast.error("The Full Name should be greater than 3 characters");
      return false;
    }
    // if (email && !email.match(mailformat)) {
    //   toast.error("Please enter a valid email");
    //   return false;
    // }
    return true;
  };
  const handleUserUpdate = async (e: any) => {
    e.preventDefault();

    const { fullName, cryptoWallet, email, phone } = userValues;

    if (!handleUserValidation()) {
      // show error
      return;
    }
    let updatedUser = {
      ...auth.user,
      fullName: fullName ?? auth.user.fullName,
      email: email ?? auth.user.email,
      phone: phone ?? auth.user.phone,
      cryptoWallet: cryptoWallet ?? auth.user.cryptoWallet,
    };
    const { response, errors } = await UpdateUserAPI({
      auth_token: auth.auth_token,
      ...updatedUser,
    });

    if (errors) {
      return errors.map((err: any) => toast.error(err.message));
    }

    if (!response || !response.success) {
      return toast.error(
        "Server is not responding correctly. Please Try again."
      );
    }

    toast.success("User Information has been updated successfully!");

    updateUser();
  };
  return (
    <div className="card-body">
      <h5 className="card-title">General Information</h5>
      <div className="settings-profile">
        <form>
          {/* <img src={"img/avatar.svg"} alt="avatar" />
                            <div className="custom-file">
                              <input
                                type="file"
                                className="custom-file-input"
                                id="fileUpload"
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="fileUpload"
                              >
                                Choose avatar
                              </label>
                            </div> */}
          <div className="form-row mt-4">
            <div className="col-md-6">
              <label htmlFor="formFirst">Full name</label>
              <input
                id="formFirst"
                type="text"
                className="form-control"
                placeholder="Full Name"
                name="fullName"
                value={userValues.fullName}
                onChange={handleUserChange}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="emailAddress">Email</label>
              <input
                id="emailAddress"
                type="text"
                className="form-control"
                placeholder="Enter your email"
                name="email"
                disabled
                style={{ opacity: "0.5" }}
                value={auth.user.email}
                onChange={handleUserChange}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="phoneNumber">Phone</label>
              <input
                id="phoneNumber"
                type="text"
                className="form-control"
                placeholder="Enter phone number"
                name="phone"
                value={userValues.phone}
                onChange={handleUserChange}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="walletAddress">Crypto Wallet Address</label>
              <input
                id="walletAddress"
                type="text"
                className="form-control"
                placeholder="Enter wallet address"
                name="cryptoWallet"
                value={userValues.cryptoWallet}
                onChange={handleUserChange}
              />
            </div>
            {/* <div className="col-md-6">
                                <label htmlFor="selectLanguage">Language</label>
                                <select
                                  id="selectLanguage"
                                  className="custom-select"
                                >
                                  <option defaultValue>English</option>
                                  <option>Mandarin Chinese</option>
                                  <option>Spanish</option>
                                  <option>Arabic</option>
                                  <option>Russian</option>
                                </select>
                              </div>
                              <div className="col-md-6">
                                <label htmlFor="selectCurrency">Currency</label>
                                <select
                                  id="selectCurrency"
                                  className="custom-select"
                                >
                                  <option defaultValue>USD</option>
                                  <option>EUR</option>
                                  <option>GBP</option>
                                  <option>CHF</option>
                                </select>
                              </div> */}
            <div className="col-md-12">
              <input type="submit" value="Update" onClick={handleUserUpdate} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GeneralInfo;
