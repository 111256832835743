import React, { useContext } from "react";
import { UserContext } from "../context/UserContext";
import { Route } from "react-router-dom";
import Login from "../pages/login";

const ProtectedRoute = ({ children }: any) => {
  const { auth } = useContext(UserContext);
  return auth.signedIn ? (
    <Route>{children}</Route>
  ) : (
    <Route>
      <Login />
    </Route>
  );
};

export default ProtectedRoute;
