import { UserContext } from "context/UserContext";
import React, { useContext } from "react";

interface BalanceInfoProps {
  referralBalance?: number | string;
  rebateBalance?: number | string;
}

const BalanceInfo: React.FC<BalanceInfoProps> = ({
  referralBalance,
  rebateBalance,
}) => {
  const { auth } = useContext(UserContext);

  return (
    <div className="flex flex-col lg:flex-row justify-between gap-4 rounded-md">
      <div className="flex-grow flex flex-col w-full bg-[#121417] hover:scale-105 transition-all duration-300 cursor-pointer text-white p-4 rounded-md text-center justify-center items-center gap-10">
        <div className="text-sm flex items-center gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
          CURRENT BALANCE
        </div>
        <div className="text-4xl font-bold">
          ${auth.user.wallet.balance.toFixed(1)}
        </div>
      </div>
      <div className="flex-grow flex flex-col w-full bg-[#121417] text-white p-4 hover:scale-105 transition-all duration-300 cursor-pointer rounded-md text-center justify-center items-center gap-10">
        <div className="text-sm flex items-center gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
          REBATE BALANCE
        </div>
        <div className="text-4xl font-bold">
          ${auth.user.rebateBalance.toFixed(1)}
        </div>
      </div>
      <div className="flex-grow flex flex-col w-full bg-[#121417] text-white p-4 hover:scale-105 transition-all duration-300 cursor-pointer rounded-md text-center justify-center items-center gap-10">
        <div className="text-sm flex items-center gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
          REFERRAL BALANCE
        </div>
        <div className="text-4xl font-bold">
          ${auth.user.referralBalance.toFixed(1)}
        </div>
      </div>
    </div>
  );
};

export default BalanceInfo;
