import MainLayout from "../components/layout";

export default function AboutUs() {
  return (
    <MainLayout>
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>
                vTrading is one of the world’s smartest online platforms. We
                offer derivatives on cryptocurrencies to millions of registered
                users across the globe.
              </p>

              <p>
                From inception, our goal was to break free of the high
                commissions and clunky products offered by traditional platforms
                out there. Who try to lure you of giving back 100% of your
                investment and then run away with your hard-earned money. Also,
                we aim to deliver a first-class experience to digitally inclined
                traders, regardless of the size of their accounts.
              </p>

              <p>
                With a journey spanning more than 22 years of experience in the
                field of IT and Emerging Tech., we have grown to over 10
                thousand customers worldwide in just 6 months. But our mission
                has remained the same.
              </p>
            </div>
            <div className="col-md-12 quote">
              <h1>“Make trading accessible to anyone, anywhere.”</h1>
            </div>
            <div className="col-md-12">
              <h1>Our Values:</h1>

              <h2>Integrity</h2>

              <p>
                We serve our customers with fairness and transparency. We settle
                all contracts by the book and speak plainly and truthfully.
              </p>
              <hr />

              <h2>Customer focus</h2>

              <p>
                We put the customer first and strive to build products that
                deliver the best customer experience.
              </p>
              <hr />

              <h2>Competence</h2>

              <p>
                We value colleagues with an aptitude to learn and grow and the
                ability to use good judgement.
              </p>
              <hr />

              <h2>Teamwork</h2>
              <p>
                We value team players that collaborate freely across departments
                with humility and ambition.
              </p>
              <hr />

              <h1>Our Principles:</h1>

              <h2>Be reliable</h2>

              <p>
                We settle all contracts fairly, process all deposits and
                withdrawals promptly, and provide reliable support and a
                high-quality trading experience to all our customers.
              </p>
              <hr />

              <h2>Be fair</h2>
              <p>
                We treat all customers equitably, handle complaints with
                integrity, and offer competitive prices with no hidden costs and
                no artificial barriers on customer withdrawals.
              </p>
              <hr />
              <h2>Be transparent</h2>
              <p>
                We speak plainly to avoid ambiguity, disclose the terms of all
                contracts, and are clear about the risks of trading and how we
                make money.
              </p>
              <hr />
              <h2>Be responsible</h2>
              <p>
                We don’t practice hard-selling, offer financial or trading
                advice, nor make promises of guaranteed returns. We don’t
                encourage vulnerable people to trade and implement controls to
                prevent any unlawful activity.
              </p>
              <hr />

              <h1>vTrading in Numbers:</h1>
              <p>
                We aim to deliver market-leading products that are trusted
                around the world.
              </p>
            </div>
          </div>
          <div className="about-numbers">
            <div className="col-md-4">
              <div className="landing-feature-item">
                <h3 className="green">2.5M+</h3>
                <p>Traders worldwide</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="landing-feature-item">
                <h3 className="green">$26K+</h3>
                <p>Withdrawals last month</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="landing-feature-item">
                <h3 className="green">1M+</h3>
                <p>Trades last month</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="landing-feature-item">
                <h3 className="green">$100M+</h3>
                <p>Total trade turnover</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
