import Account from "components/account";
import MainLayout from "components/layout";
import React from "react";

const Transactions = () => {
  return (
    <MainLayout>
      <Account tab={"transactions"} />
    </MainLayout>
  );
};

export default Transactions;
