import { getUnauthenticatedApi } from "../apiGenerator";
import { Response } from "../_types";
import { GetDepositWalletsResponse } from "../_types/requests/platform";

export const GetDepositWalletsAPI = (): Promise<
  Response<GetDepositWalletsResponse>
> =>
  getUnauthenticatedApi<GetDepositWalletsResponse>({
    url: `/platform/deposit-wallets`,
  });
