import React, { useState } from "react";
import Reward from "./tabs/Reward";
import Referral from "./tabs/Referral";
import PromotionGuide from "./tabs/PromotionGuide";
import { User } from "api/common/api";

interface ReffralTabsProps {
  invitedUser: User[];
  referralReward: any;
  referralBalance: number | string;
  rebateBalance: number | string;
  settings: any;
}

const ReffralTabs: React.FC<ReffralTabsProps> = ({
  invitedUser,
  referralReward,
  settings,
}) => {
  const [activeTab, setActiveTab] = useState("Referral and Reward");

  const renderContent = () => {
    switch (activeTab) {
      case "Referral and Reward":
        return (
          <Reward invitedUser={invitedUser} referralReward={referralReward} />
        );
      case "Promotion Guide":
        return <PromotionGuide settings={settings} />;
      default:
        return null;
    }
  };

  return (
    <div className="!border border-[#27282c] bg-[#181a1f] text-white p-4 rounded-2xl">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
        <div className="flex space-x-4 sm:space-x-8">
          {["Referral and Reward", "Promotion Guide"].map((tab) => (
            <button
              key={tab}
              className={`pb-2 text-md ${
                activeTab === tab ? "border-b-2 border-[#007bff] font-bold" : ""
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>
      <div>{renderContent()}</div>
    </div>
  );
};

export default ReffralTabs;
