import { User } from "api/common/api";
import { createContext } from "react";

export interface authType {
  signedIn: boolean;
  auth_token: string;
  user: User;
}
interface userContext {
  auth: authType;
  setAuth: (auth: authType) => void;
  updateUser: () => Promise<void>;
}

const defaultWallet = {
  owner: "",
  balance: 0,
  active: false,
};
const defaultUser = {
  id: "",
  fullName: "",
  email: "",
  password: "",

  country: "",
  phone: "",

  revenue: 0,

  isBlocked: false,
  email_verified: false,

  referredBy: null,
  referralCode: "",

  wallet: defaultWallet,
  cryptoWallet: "",

  createdAt: "",
  status: "inActive",
  level: 0,
  referralBalance: 0, // Include referral balance
  rebateBalance: 0,
};

export const defaultAuth = {
  signedIn: false,
  auth_token: "",
  user: defaultUser,
};

const defaultContext = {
  auth: defaultAuth,
  setAuth: (e: authType) => console.log(e),
  updateUser: async () => console.log("update"),
};

export const UserContext = createContext<userContext>(defaultContext);
