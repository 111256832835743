import { getAuthenticatedApi, postUnauthenticatedApi } from "../apiGenerator";
import {
  AuthenticatedPropsGenerator,
  CurrentUserRequest,
  CurrentUserResponse,
  LoginRequest,
  LoginResponse,
  Response,
  SignUpRequest,
  SignUpResponse,
} from "../_types";

export const LoginAPI = (
  loginRequestObj: LoginRequest
): Promise<Response<LoginResponse>> =>
  postUnauthenticatedApi<LoginRequest, LoginResponse>({
    url: `/user/auth/login`,
    body: loginRequestObj,
  });

export const SignupAPI = (
  signupRequestObj: SignUpRequest
): Promise<Response<SignUpResponse>> =>
  postUnauthenticatedApi<SignUpRequest, SignUpResponse>({
    url: `/user/auth/signup`,
    body: signupRequestObj,
  });

export const CurrentUserAPI = ({
  auth_token,
}: AuthenticatedPropsGenerator<CurrentUserRequest>): Promise<
  Response<CurrentUserResponse>
> =>
  getAuthenticatedApi<CurrentUserResponse>({
    url: `/user/auth/current-user`,
    auth_token,
  });
