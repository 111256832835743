import { useCallback, useContext, useEffect, useState } from "react";
import Betting from "../components/exchange/Betting";
import TradesLog from "../components/exchange/TradesLog";
import TradeChart from "../components/exchange/TradeChart";
import TradesLayout from "../components/layout/TradesLayout";

import { UserContext } from "context/UserContext";
import { Bet, GetReferralRewardAPI, GetUserBetsAPI } from "api/common/api";
import { toast } from "react-toastify";

const Exchange = () => {
  const { auth } = useContext(UserContext);
  const [betsLog, setBetsLog] = useState<Bet[]>([]);
  const [activeBets, setActiveBets] = useState<Bet[]>([]);
  const getReferralReward = async () => {
    const { response, errors } = await GetReferralRewardAPI({
      auth_token: auth.auth_token,
    });

    if (errors) {
      errors.map((err: any) => toast.error(err.message));
      return;
    }

    if (!response || !response.success) {
      toast.error("Server is not responding correctly.");
      return;
    }
  };
  const processBets = useCallback((bets: Bet[]) => {
    const dateNow = new Date();

    //* PUSH THE STILL ACTIVE BETS TO THE BETTING COOLDOWN TIMER
    setActiveBets(
      bets.filter(
        (bet) =>
          Date.parse(bet.createdAt) + (bet.timer + 1) * 60000 >
          dateNow.getTime()
      )
    );

    //* PUSH ALL THE BETS TO THE TRADESLOG TABLE
    setBetsLog(bets);
  }, []);

  const updateLog = useCallback(async () => {
    const { response } = await GetUserBetsAPI({
      auth_token: auth.auth_token,
    });

    if (response) {
      processBets(response.bets);
    }
  }, [auth.auth_token, processBets]);

  useEffect(() => {
    updateLog();
    getReferralReward();
    const updateTimer = setInterval(() => {
      updateLog();
    }, 10 * 200);
    return () => clearInterval(updateTimer);
    // eslint-disable-next-line
  }, []);
  return (
    <TradesLayout>
      <TradeChart />
      <Betting activeBets={activeBets} updateLog={updateLog} />
      <TradesLog betsLog={betsLog} />
    </TradesLayout>
  );
};

export default Exchange;
