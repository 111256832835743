import { GetUserDepositsAPI } from "api/common/api/user/transactions";
import { UserContext } from "context/UserContext";
import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { formatDateTime } from "utils/formatToDate";
import { Clipboard } from "react-bootstrap-icons";
import * as XLSX from "xlsx";

const Transactions: React.FC = () => {
  const { auth } = useContext(UserContext);
  const [deposits, setDeposits] = useState<any>([]);
  const [timeFilter, setTimeFilter] = useState<string>("last7days");
  console.log(deposits, "deposits");

  const getTransactions = async () => {
    const { response, errors } = await GetUserDepositsAPI({
      auth_token: auth.auth_token,
    });

    if (errors) {
      errors.map((err: any) => toast.error(err.message));
      return;
    }

    if (!response) {
      toast.error("Server is not responding correctly.");
      return;
    }

    setDeposits(response);
  };

  useEffect(() => {
    getTransactions();
  }, []);

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard!");
  };

  const handleTimeFilterChange = (filter: string) => {
    setTimeFilter(filter);
    // Apply filtering logic here based on the selected filter
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      deposits.map(({ amount, createdAt, from, hash }: any) => ({
        "Date & Time": formatDateTime(createdAt),
        Wallet: from,
        Amount: amount,
        Hash: hash,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Transactions");
    XLSX.writeFile(workbook, "Transactions.xlsx");
  };

  return (
    <div className="lg:!border border-[#27282c] bg-[#181a1f] text-white p-4 rounded-lg">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-bold">Transfer History</h2>
        <button
          onClick={exportToExcel}
          className="mt-2 sm:mt-0 bg-transparent border border-gray-700 rounded-xl text-[#007bff] px-4 py-2"
        >
          Export
        </button>
      </div>

      <div className="overflow-x-scroll">
        <table className="min-w-[500px] md:w-full bg-[#121417] rounded-lg">
          <thead>
            <tr>
              <th className="py-3 px-4 text-nowrap ">Date & Time</th>
              <th className="py-3 px-4">Wallet</th>
              <th className="py-3 px-4">Amount</th>
              <th className="py-3 px-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {deposits.length > 0 ? (
              deposits.map(({ amount, createdAt, from, hash }: any) => (
                <tr key={hash} className="border-t border-gray-700">
                  <td className="py-3 px-4 text-nowrap">
                    {formatDateTime(createdAt)}
                  </td>
                  <td className="py-3 px-4 flex items-center">
                    {from}
                    <Clipboard
                      className="ml-2 cursor-pointer"
                      onClick={() => handleCopy(from)}
                    />
                  </td>
                  <td className="py-3 px-4">{amount}</td>
                  <td className="py-3 px-4">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://tronscan.org/#/transaction/${hash}`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="orange"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="white"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                        />
                      </svg>
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} className="py-4 text-center">
                  <div className="flex justify-center gap-2 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="size-6"
                    >
                      <path d="M21 6.375c0 2.692-4.03 4.875-9 4.875S3 9.067 3 6.375 7.03 1.5 12 1.5s9 2.183 9 4.875Z" />
                      <path d="M12 12.75c2.685 0 5.19-.586 7.078-1.609a8.283 8.283 0 0 0 1.897-1.384c.016.121.025.244.025.368C21 12.817 16.97 15 12 15s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.285 8.285 0 0 0 1.897 1.384C6.809 12.164 9.315 12.75 12 12.75Z" />
                      <path d="M12 16.5c2.685 0 5.19-.586 7.078-1.609a8.282 8.282 0 0 0 1.897-1.384c.016.121.025.244.025.368 0 2.692-4.03 4.875-9 4.875s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.284 8.284 0 0 0 1.897 1.384C6.809 15.914 9.315 16.5 12 16.5Z" />
                      <path d="M12 20.25c2.685 0 5.19-.586 7.078-1.609a8.282 8.282 0 0 0 1.897-1.384c.016.121.025.244.025.368 0 2.692-4.03 4.875-9 4.875s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.284 8.284 0 0 0 1.897 1.384C6.809 19.664 9.315 20.25 12 20.25Z" />
                    </svg>
                    <span>No data</span>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Transactions;
