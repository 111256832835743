import React from "react";

const LandingInfo = () => {
  return (
    <div className="landing-info-one landing-info-one-bg mtb100">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h2>The most advance and secure cryptocurrency platform.</h2>
            <p>
              Take advantage of a highly liquid market with round-the-clock
              trading. Profit from correctly predicting the movement of world's
              most popular cryptocurrencies.
            </p>
            <ul>
              <li>
                <i className="icon ion-ios-checkmark-circle"></i> Live Technical
                Analysis
              </li>
              <li>
                <i className="icon ion-ios-checkmark-circle"></i> Live
                Cryptocurrency Price
              </li>
              <li>
                <i className="icon ion-ios-checkmark-circle"></i> Live Trade
                Predictions
              </li>
              <li>
                <i className="icon ion-ios-checkmark-circle"></i> Deposit &
                Withdraw
              </li>
              <li>
                <i className="icon ion-ios-checkmark-circle"></i> Manage Wallets
              </li>
              <li>
                <i className="icon ion-ios-checkmark-circle"></i> Recent Trades
                Log
              </li>
              <li>
                <i className="icon ion-ios-checkmark-circle"></i> Zero Scams
              </li>
              <li>
                <i className="icon ion-ios-checkmark-circle"></i> Zero Monthly
                Fees
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingInfo;
