import React from "react";

const LandingNumber = () => {
  return (
    <div className="landing-number">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <h2>$657B</h2>
            <p>Quarterly volume traded</p>
          </div>
          <div className="col-md-4">
            <h2>100+</h2>
            <p>Countries supported</p>
          </div>
          <div className="col-md-4">
            <h2>56+M</h2>
            <p>Verified users</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingNumber;
