import React from "react";
import VipLevel from "./VipLevels";

const PromotionGuide = ({ settings }: any) => {
  return (
    <div className="text-white min-h-screen lg:p-4 sm:p-8">
      <div className="text-center mb-8">
        <h1 className="text-2xl sm:text-4xl font-bold mb-4">
          How to earn referral rewards effortlessly
        </h1>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-8">
        <div className="bg-[#181a1f] p-4 sm:p-6 rounded-lg text-left border-2 border-solid border-transparent animate-border-pulse">
          <h2 className="text-2xl sm:text-3xl font-bold mb-2 text-gray-600">
            01
          </h2>
          <p className="text-xl sm:text-3xl font-semibold my-2">Get link</p>
          <p className="text-xs sm:text-sm">
            Register or log in to Trading Plane to get your exclusive referral
            link.
          </p>
        </div>

        <div className="bg-[#181a1f] p-4 sm:p-6 rounded-lg text-left border-2 border-solid border-transparent animate-border-pulse">
          <h2 className="text-2xl sm:text-3xl font-bold mb-2 text-gray-600">
            02
          </h2>
          <p className="text-xl sm:text-3xl font-semibold my-2">
            Invite friends
          </p>
          <p className="text-xs sm:text-sm">
            Share your exclusive referral link with friends.
          </p>
        </div>
        <div className="bg-[#181a1f] p-4 sm:p-6 rounded-lg text-left border-2 border-solid border-transparent animate-border-pulse">
          <h2 className="text-2xl sm:text-3xl font-bold mb-2 text-gray-600">
            03
          </h2>
          <p className="text-xl sm:text-3xl font-semibold my-2">
            Earn referral reward
          </p>
          <p className="text-xs sm:text-sm">
            With every transaction your friend completes, you both can share up
            to 40% rebate.
          </p>
        </div>
      </div>
      <VipLevel settings={settings} />
      <div>
        <h2 className="text-2xl sm:text-3xl font-bold mb-4">FAQ</h2>
        <div className="space-y-4">
          <details className="bg-gray-800 p-4 rounded-lg">
            <summary className="font-semibold">
              01 What is Trading Plane Referral Reward?
            </summary>
            <p className="mt-2 text-xs sm:text-sm">
              Trading Plane Referral Reward is a program designed to reward
              users for inviting their friends to join the platform. By sharing
              your referral link, you can earn a percentage of the transaction
              fees paid by your referred friends.
            </p>
          </details>
          <details className="bg-gray-800 p-4 rounded-lg">
            <summary className="font-semibold">
              02 How to invite friends to get the referral reward?
            </summary>
            <p className="mt-2 text-xs sm:text-sm">
              To invite friends, log in to your Trading Plane account and copy
              your unique referral link. Share this link with your friends
              through social media, email, or any other method. When your
              friends sign up using your link and start trading, you will earn
              rewards.
            </p>
          </details>
          <details className="bg-gray-800 p-4 rounded-lg">
            <summary className="font-semibold">
              03 How to receive more referral rewards?
            </summary>
            <p className="mt-2 text-xs sm:text-sm">
              To maximize your referral rewards, encourage your friends to trade
              frequently and with higher volumes. You can also expand your reach
              by sharing your referral link on multiple platforms and engaging
              with a broader audience.
            </p>
          </details>
          <details className="bg-gray-800 p-4 rounded-lg">
            <summary className="font-semibold">
              04 Why did I not receive any referral reward after referring a
              friend?
            </summary>
            <p className="mt-2 text-xs sm:text-sm">
              There could be several reasons: your friend may not have completed
              the sign-up process using your link, they may not have met the
              minimum trading requirements, or their account status may not be
              active. Ensure that your friend follows through with the entire
              process.
            </p>
          </details>
        </div>
      </div>
      <div className="flex flex-col mt-10 sm:mt-20 gap-10">
        <h2 className="text-2xl sm:text-3xl font-bold">Referral Rules</h2>
        <div className="space-y-4 text-xs sm:text-sm">
          <p>
            1. You must be a registered user of Trading Plane to participate in
            the referral program.
          </p>
          <p>
            2. Each user receives a unique referral link to share with friends
            and family.
          </p>
          <p>
            3. Referral rewards are earned only when the referred user completes
            the sign-up process using the referral link and meets the minimum
            trading requirements.
          </p>
          <p>
            4. The referred user must remain active for you to continue earning
            rewards from their transactions.
          </p>
          <p>
            5. Referral rewards are calculated based on the transaction fees
            paid by the referred user.
          </p>
          <p>
            6. You will receive a direct rebate reward from the transactions of
            users you directly refer.
          </p>
          <p>
            7. You will receive an indirect rebate reward from the transactions
            of users referred by your direct referrals.
          </p>
          <p>
            8. You can earn rebate rewards for every transaction made by your
            direct and indirect referrals.
          </p>
          <p>
            9. You can earn a referral reward once you reach the required number
            of active referrals for each level.
          </p>
          <p>
            10. The percentage of referral rewards and rebate rewards increases
            as you advance to higher levels.
          </p>
          <p>
            11. The required number of referrals and the reward percentages for
            each level are specified in the configuration settings.
          </p>
          <p>
            12. Ensure that your referred users comply with all Trading Plane
            policies and guidelines to be eligible for rewards.
          </p>
          <p>
            13. The referral program is subject to change, and Trading Plane
            reserves the right to modify the rules and rewards structure at any
            time.
          </p>
          <p>
            14. Any misuse of the referral program, including fraudulent
            activities, will result in disqualification and forfeiture of
            rewards.
          </p>
          <p>
            15. Referral rewards are paid out according to the schedule
            specified by Trading Plane.
          </p>
          <p>
            16. Keep track of your referral activity and rewards through your
            Trading Plane account dashboard.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PromotionGuide;
