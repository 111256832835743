import React from "react";
import Hero from "../components/landing/Hero";
import LandingFeature from "../components/landing/LandingFeature";
import LandingInfo from "../components/landing/LandingInfo";
import LandingNumber from "../components/landing/LandingNumber";
import LandingStart from "../components/landing/LandingStart";
import LandingSub from "../components/landing/LandingSub";
import SymbolSection from "../components/landing/SymbolSection";
import Tape from "../components/landing/Tape";
import MainLayout from "../components/layout";

export default function Home() {
  return (
    <>
      <MainLayout>
        <Hero />
        <Tape />
        <LandingInfo />
        <SymbolSection />
        <LandingFeature />
        <LandingNumber />
        <LandingStart />
        <LandingSub />
      </MainLayout>
    </>
  );
}
