import QRCodeModal from "components/account/referral/QRCodeModal";
import { useState } from "react";
import { FaCopy, FaQrcode } from "react-icons/fa";
import { toast } from "react-toastify";

interface ReferralSectionProps {
  referralCode: any;
}

const ReferralSection: React.FC<ReferralSectionProps> = ({ referralCode }) => {
  const [showQRCode, setShowQRCode] = useState(false);
  const referralLink = `https://www.tradingplane.com/signup?referral=${referralCode}`;

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard");
  };

  return (
    <div className="flex xl:w-1/3 w-full 2xl:w-full flex-col lg:flex-row items-start lg:items-end space-y-4 lg:space-y-0 lg:space-x-4 align-middle">
      <div className="flex flex-col gap-2  ">
        <span className="text-white">Referral code</span>
        <div className="!border w-24 border-gray-700 text-white px-4 py-2 rounded flex items-center justify-between space-x-1">
          <span>{referralCode}</span>
          <button onClick={() => copyToClipboard(referralCode)}>
            <FaCopy className="text-white cursor-pointer" />
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-2 max-w-full ">
        <span className="text-white">Referral link</span>
        <div className="!border border-gray-700 max-w-96 text-white px-4 py-2 rounded flex items-center justify-between space-x-1">
          <span className="truncate">{referralLink}</span>
          <button onClick={() => copyToClipboard(referralLink)}>
            <FaCopy className="text-white cursor-pointer" />
          </button>
        </div>
      </div>

      <div className="flex items-center">
        <button
          onClick={() => setShowQRCode(true)}
          className="text-white px-2 py-1 rounded-md flex items-center bg-[#007bff]"
        >
          <FaQrcode size={30} className="text-white cursor-pointer" />
        </button>
        <QRCodeModal
          show={showQRCode}
          onClose={() => setShowQRCode(false)}
          referralLink={referralLink}
          referralCode={referralCode}
        />
      </div>
    </div>
  );
};

export default ReferralSection;
