import React from "react";
import { Link } from "react-router-dom";
import MainLayout from "../components/layout";

export default function ContactUs() {
  const mailtoHandler = (e: any) => {
    window.location.href = "mailto:info@vtrading.biz";
    e.preventDefault();
  };
  return (
    <MainLayout>
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Contact Us</h1>

              <p>
                vTrading.biz is a free trading portal which does not charge you
                trade commissions, and specializes in binary options trading on
                cryptocurrencies. We readily welcome any feedback from our
                users. Please use the contact details below.
              </p>
              <p>
                Also, if you’re interested in advertising on vTrading.biz then
                you can also contact us below.
              </p>
              <p>
                Email:{" "}
                <Link to="mailto:info@vtrading.biz" onClick={mailtoHandler}>
                  info@vtrading.biz
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
