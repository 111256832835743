import { CustomError } from "./_types";

// eslint-disable-next-line
export const errorParser = (error: any): CustomError[] => {
  if (
    !error ||
    !error.message ||
    !error.response?.data ||
    !error.response.data.errors
  ) {
    return [
      {
        message: "Error connecting to server",
      },
    ];
  }
  return error.response.data.errors;
};
