import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import EarningsChart from "./EarningsChart";
import BalanceInfo from "./BalanceInfo";
import { User } from "api/common/api";

interface RewardProps {
  invitedUser?: User[];
  referralReward?: any;
}

const Reward: React.FC<RewardProps> = ({ invitedUser, referralReward }) => {
  return (
    <div className="flex flex-col gap-10">
      <div className="lg:!border border-[#27282c] bg-[#181a1f] text-white rounded-md flex flex-col gap-10 lg:p-4">
        <BalanceInfo />
        <EarningsChart />
      </div>
      <RewardHistory referralReward={referralReward} />
      <ReferralHistory invitedUser={invitedUser} />
    </div>
  );
};

export default Reward;

const RewardHistory: React.FC<RewardProps> = ({ referralReward }) => {
  const [tableData, setTableData] = useState<
    Array<{
      level: number;
      paid: boolean;
      reward: number;
      type: string;
      rewardDate: string;
      fromUserName: string;
      fromUserEmail: string;
      rebateType?: string;
    }>
  >([]);
  const [filteredData, setFilteredData] = useState(tableData);
  const [filterDate, setFilterDate] = useState<string>("");
  const [filterType, setFilterType] = useState<string>("");

  useEffect(() => {
    if (referralReward && referralReward.rewards) {
      setTableData(referralReward.rewards);
      setFilteredData(referralReward.rewards);
    }
  }, [referralReward]);

  useEffect(() => {
    let data = tableData;
    if (filterDate) {
      data = data.filter((item) =>
        new Date(item.rewardDate).toISOString().includes(filterDate)
      );
    }
    if (filterType) {
      data = data.filter((item) => item.rebateType === filterType);
    }
    setFilteredData(data);
  }, [filterDate, filterType, tableData]);

  const exportToXLSX = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Rewards");
    XLSX.writeFile(workbook, "reward_history.xlsx");
  };

  return (
    <div className="lg:!border border-[#27282c] bg-[#181a1f] text-white lg:p-6 rounded-md">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Reward History</h2>
        <button
          onClick={exportToXLSX}
          className="mt-2 sm:mt-0 bg-transparent border border-gray-700 rounded-xl text-[#007bff] px-4 py-2"
        >
          Export
        </button>
      </div>
      <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 mb-4">
        {/* <select
          className="bg-[#121417] text-white p-2 rounded-md"
          onChange={(e) => setFilterType(e.target.value)}
        >
          <option value="">All Types</option>
          <option value="direct">Direct</option>
          <option value="indirect">Indirect</option>
        </select> */}
        <input
          type="date"
          className="bg-[#121417] text-white p-2 rounded-md"
          onChange={(e) => setFilterDate(e.target.value)}
        />
      </div>
      <div className="overflow-x-scroll ">
        <table className="sm:min-w-[500px] md:w-full bg-[#121417] rounded-md">
          <thead>
            <tr>
              <th className="py-2 px-4 text-left">#</th>
              <th className="py-2 px-4 text-left">Name</th>
              <th className="py-2 px-4 text-left">Email</th>
              <th className="py-2 px-4 text-left">Type</th>
              <th className="py-2 px-4 text-left">Reward</th>
              <th className="py-2 px-4 text-left">Date</th>
              <th className="py-2 px-4 text-left">Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map(
                (
                  {
                    level,
                    paid,
                    reward,
                    type,
                    rewardDate,
                    fromUserName,
                    fromUserEmail,
                    rebateType,
                  },
                  index
                ) => (
                  <tr key={index}>
                    <td className="py-2 px-4">{index + 1}</td>
                    <td className="py-2 px-4">{fromUserName}</td>
                    <td className="py-2 px-4">{fromUserEmail}</td>
                    <td className="py-2 px-4">
                      {type} {rebateType ? `(${rebateType})` : ""}
                    </td>
                    <td className="py-2 px-4">{reward.toFixed(1)}$</td>
                    <td className="py-2 px-4">
                      {new Date(rewardDate).toLocaleDateString()}
                    </td>
                    <td className="py-2 px-4">
                      {paid === true ? (
                        <div className="rounded-lg bg-green-700 text-white w-14 p-1 text-center">
                          Paid
                        </div>
                      ) : (
                        <div className="rounded-lg bg-orange-700 text-white w-14 p-2 text-center">
                          Pending
                        </div>
                      )}
                    </td>
                  </tr>
                )
              )
            ) : (
              <tr>
                <td colSpan={7} className="py-4 text-center">
                  <div className="flex justify-center gap-2 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="size-6"
                    >
                      <path d="M21 6.375c0 2.692-4.03 4.875-9 4.875S3 9.067 3 6.375 7.03 1.5 12 1.5s9 2.183 9 4.875Z" />
                      <path d="M12 12.75c2.685 0 5.19-.586 7.078-1.609a8.283 8.283 0 0 0 1.897-1.384c.016.121.025.244.025.368C21 12.817 16.97 15 12 15s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.285 8.285 0 0 0 1.897 1.384C6.809 12.164 9.315 12.75 12 12.75Z" />
                      <path d="M12 16.5c2.685 0 5.19-.586 7.078-1.609a8.282 8.282 0 0 0 1.897-1.384c.016.121.025.244.025.368 0 2.692-4.03 4.875-9 4.875s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.284 8.284 0 0 0 1.897 1.384C6.809 15.914 9.315 16.5 12 16.5Z" />
                      <path d="M12 20.25c2.685 0 5.19-.586 7.078-1.609a8.282 8.282 0 0 0 1.897-1.384c.016.121.025.244.025.368 0 2.692-4.03 4.875-9 4.875s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.284 8.284 0 0 0 1.897 1.384C6.809 19.664 9.315 20.25 12 20.25Z" />
                    </svg>
                    <span>No data</span>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ReferralHistory: React.FC<RewardProps> = ({ invitedUser }) => {
  const [tableData, setTableData] = useState<
    Array<{
      Name: string;
      Email: string;
      Level: string;
      Status: string;
      createdAt: string;
      country: string;
    }>
  >([]);
  const [filteredData, setFilteredData] = useState(tableData);
  const [filterDate, setFilterDate] = useState<string>("");
  const [filterStatus, setFilterStatus] = useState<string>("");

  useEffect(() => {
    if (invitedUser) {
      const data = invitedUser.map((user) => ({
        Name: user.fullName,
        Email: user.email,
        Level: user.level.toString(),
        Status: user.status,
        createdAt: user.createdAt,
        country: user.country,
      }));
      setTableData(data);
      setFilteredData(data);
    }
  }, [invitedUser]);

  useEffect(() => {
    let data = tableData;
    if (filterDate) {
      data = data.filter((item) =>
        new Date(item.createdAt).toISOString().includes(filterDate)
      );
    }
    if (filterStatus) {
      data = data.filter((item) => item.Status === filterStatus);
    }
    setFilteredData(data);
  }, [filterDate, filterStatus, tableData]);

  const exportToXLSX = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Invited Friends");
    XLSX.writeFile(workbook, "invited_friends.xlsx");
  };

  return (
    <div className="lg:!border border-[#27282c] bg-[#181a1f] text-white lg:p-6 rounded-md">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Invited Friends</h2>
        <button
          onClick={exportToXLSX}
          className="mt-2 sm:mt-0 bg-transparent border border-gray-700 rounded-xl text-[#007bff] px-4 py-2"
        >
          Export
        </button>
      </div>
      <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 mb-4">
        <select
          className="bg-[#121417] text-white p-2 rounded-md"
          onChange={(e) => setFilterStatus(e.target.value)}
        >
          <option value="">All Status</option>
          <option value="Active">Active</option>
          <option value="inActive">Pending</option>
        </select>
        <input
          type="date"
          className="bg-[#121417] text-white p-2 rounded-md"
          onChange={(e) => setFilterDate(e.target.value)}
        />
      </div>
      <div className="overflow-x-scroll">
        <table className="sm:min-w-[500px] md:w-full bg-[#121417] rounded-md">
          <thead>
            <tr>
              <th className="py-2 px-4 text-left">#</th>
              <th className="py-2 px-4 text-left">Name</th>
              <th className="py-2 px-4 text-left">Email</th>
              <th className="py-2 px-4 text-left">Country</th>
              <th className="py-2 px-4 text-left">Created At</th>
              <th className="py-2 px-4 text-left">Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map(
                ({ Name, Email, country, createdAt, Status }, index) => (
                  <tr key={index}>
                    <td className="py-2 px-4">{index + 1}</td>
                    <td className="py-2 px-4">{Name}</td>
                    <td className="py-2 px-4">{Email}</td>
                    <td className="py-2 px-4">{country}</td>
                    <td className="py-2 px-4">
                      {new Date(createdAt).toLocaleDateString()}
                    </td>
                    <td className="py-2 px-4">
                      {Status === "inActive" ? (
                        <div className="rounded-lg bg-orange-700 text-white w-16 p-1 text-center">
                          Pending
                        </div>
                      ) : (
                        <div className="rounded-lg bg-green-700 text-white w-16 p-1 text-center">
                          Active
                        </div>
                      )}
                    </td>
                  </tr>
                )
              )
            ) : (
              <tr>
                <td colSpan={6} className="py-4 text-center">
                  <div className="flex justify-center gap-2 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="size-6"
                    >
                      <path d="M21 6.375c0 2.692-4.03 4.875-9 4.875S3 9.067 3 6.375 7.03 1.5 12 1.5s9 2.183 9 4.875Z" />
                      <path d="M12 12.75c2.685 0 5.19-.586 7.078-1.609a8.283 8.283 0 0 0 1.897-1.384c.016.121.025.244.025.368C21 12.817 16.97 15 12 15s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.285 8.285 0 0 0 1.897 1.384C6.809 12.164 9.315 12.75 12 12.75Z" />
                      <path d="M12 16.5c2.685 0 5.19-.586 7.078-1.609a8.282 8.282 0 0 0 1.897-1.384c.016.121.025.244.025.368 0 2.692-4.03 4.875-9 4.875s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.284 8.284 0 0 0 1.897 1.384C6.809 15.914 9.315 16.5 12 16.5Z" />
                      <path d="M12 20.25c2.685 0 5.19-.586 7.078-1.609a8.282 8.282 0 0 0 1.897-1.384c.016.121.025.244.025.368 0 2.692-4.03 4.875-9 4.875s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.284 8.284 0 0 0 1.897 1.384C6.809 19.664 9.315 20.25 12 20.25Z" />
                    </svg>
                    <span>No data</span>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
