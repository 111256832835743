import React, { useContext, useState } from "react";
import { toast } from "react-toastify";

import { UserContext } from "../../../context/UserContext";
import { UpdatePasswordAPI } from "api/common/api";

const SecurityInfo = () => {
  const { auth } = useContext(UserContext);

  const [passwordValues, setPasswordValues] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handlePasswordChange = (e: any) => {
    setPasswordValues({ ...passwordValues, [e.target.name]: e.target.value });
  };

  const handlePassValidation = () => {
    const { newPassword, confirmPassword } = passwordValues;
    if (newPassword.length < 6) {
      toast.error("Password should be equal or greater than 6 characters");
      return false;
    }
    if (newPassword !== confirmPassword) {
      toast.error("The password and the password confimartion should match");
      return false;
    }
    return true;
  };

  const handlePassUpdate = async (e: any) => {
    e.preventDefault();
    const { oldPassword, newPassword } = passwordValues;
    if (!handlePassValidation()) {
      // show error
      return;
    }

    const { response, errors } = await UpdatePasswordAPI({
      auth_token: auth.auth_token,
      oldPassword,
      newPassword,
    });

    if (errors) {
      return errors.map((err: any) => toast.error(err.message));
    }
    if (!response || !response.success) {
      return toast.error(
        "Server is not responding correctly. Please Try again."
      );
    }
    toast.success("Password has been updated successfully!");
  };
  return (
    <div className="card-body">
      <h5 className="card-title">Security Information</h5>
      <div className="settings-profile">
        <form>
          <div className="form-row">
            <div className="col-md-6">
              <label htmlFor="currentPass">Current password</label>
              <input
                id="currentPass"
                type="password"
                className="form-control"
                placeholder="Enter your password"
                name="oldPassword"
                onChange={handlePasswordChange}
              />
            </div>
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <label htmlFor="newPass">New password</label>
              <input
                id="newPass"
                type="password"
                className="form-control"
                placeholder="Enter new password"
                name="newPassword"
                onChange={handlePasswordChange}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="newPass">Confirm new password</label>
              <input
                id="confirmPassword"
                type="password"
                className="form-control"
                placeholder="Confirm new password"
                name="confirmPassword"
                onChange={handlePasswordChange}
              />
            </div>
            {/* <div className="col-md-6">
                                <label htmlFor="securityOne">
                                  Security questions #1
                                </label>
                                <select
                                  id="securityOne"
                                  className="custom-select"
                                >
                                  <option defaultValue>
                                    What was the name of your first pet?
                                  </option>
                                  <option>
                                    What's your Mother's middle name?
                                  </option>
                                  <option>
                                    What was the name of your first school?
                                  </option>
                                  <option>
                                    Where did you travel for the first time?
                                  </option>
                                </select>
                              </div>
                              <div className="col-md-6">
                                <label htmlFor="securityAnsOne">Answer</label>
                                <input
                                  id="securityAnsOne"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your answer"
                                />
                              </div>
                              <div className="col-md-6">
                                <label htmlFor="securityTwo">
                                  Security questions #2
                                </label>
                                <select
                                  id="securityTwo"
                                  className="custom-select"
                                >
                                  <option defaultValue>Choose...</option>
                                  <option>
                                    What was the name of your first pet?
                                  </option>
                                  <option>
                                    What's your Mother's middle name?
                                  </option>
                                  <option>
                                    What was the name of your first school?
                                  </option>
                                  <option>
                                    Where did you travel for the first time?
                                  </option>
                                </select>
                              </div>
                              <div className="col-md-6">
                                <label htmlFor="securityAnsTwo">Answer</label>
                                <input
                                  id="securityAnsTwo"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your answer"
                                />
                              </div>
                              <div className="col-md-6">
                                <label htmlFor="securityThree">
                                  Security questions #3
                                </label>
                                <select
                                  id="securityThree"
                                  className="custom-select"
                                >
                                  <option defaultValue>Choose...</option>
                                  <option>
                                    What was the name of your first pet?
                                  </option>
                                  <option>
                                    What's your Mother's middle name?
                                  </option>
                                  <option>
                                    What was the name of your first school?
                                  </option>
                                  <option>
                                    Where did you travel for the first time?
                                  </option>
                                </select>
                              </div>
                              <div className="col-md-6">
                                <label htmlFor="securityFore">Answer</label>
                                <input
                                  id="securityFore"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your answer"
                                />
                              </div> */}
            <div className="col-md-12">
              <input type="submit" value="Update" onClick={handlePassUpdate} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SecurityInfo;
