import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

const Wallet = () => {
  let history = useHistory();
  const { auth } = useContext(UserContext);

  return (
    <div className="wallet">
      <div className="tab-content">
        <div className="tab-pane fade show active" id="coinBTC" role="tabpanel">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Balances</h5>
              <ul>
                <br />
                <li className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <i className="icon ion-md-cash"></i>
                    <h2>Total Revenue</h2>
                  </div>
                  <div>
                    <h3>{auth.user.revenue.toFixed(4)} USDT</h3>
                  </div>
                </li>
                <br />
                <li className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <i className="icon ion-md-checkmark"></i>
                    <h2>Available Balance</h2>
                  </div>
                  <div>
                    <h3>{auth.user.wallet.balance.toFixed(4)} USDT</h3>
                  </div>
                </li>
              </ul>

              <br />
              <button
                onClick={() => history.push("/deposit")}
                className="btn green"
              >
                Deposit
              </button>
              <button
                onClick={() => history.push("/withdraw")}
                className="btn red"
              >
                Withdraw
              </button>
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="coinETH" role="tabpanel">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Balances</h5>
              <ul>
                <li className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <i className="icon ion-md-cash"></i>
                    <h2>Total Equity</h2>
                  </div>
                  <div>
                    <h3>4.1542 ETH</h3>
                  </div>
                </li>
                <li className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <i className="icon ion-md-checkmark"></i>
                    <h2>Available Margin</h2>
                  </div>
                  <div>
                    <h3>1.334 ETH</h3>
                  </div>
                </li>
              </ul>
              <button className="btn green">Deposit</button>
              <button className="btn red">Withdraw</button>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Wallet Deposit Address</h5>
              <div className="row wallet-address">
                <div className="col-md-8">
                  <p>
                    Deposits to this address are unlimited. Note that you may
                    not be able to withdraw all of your funds at once if you
                    deposit more than your daily withdrawal limit.
                  </p>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      value="Ad87deD4gEe8dG57Ede4eEg5dREs4d5e8f4e"
                    />
                    <div className="input-group-prepend">
                      <button className="btn btn-primary">COPY</button>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <img src={"img/qr-code-light.svg"} alt="qr-code" />
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Latest Transactions</h5>
              <div className="wallet-history">
                <table className="table">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>25-04-2019</td>
                      <td>
                        <i className="icon ion-md-checkmark-circle-outline green"></i>
                      </td>
                      <td>4.5454334</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>25-05-2019</td>
                      <td>
                        <i className="icon ion-md-checkmark-circle-outline green"></i>
                      </td>
                      <td>0.5484468</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>25-06-2019</td>
                      <td>
                        <i className="icon ion-md-close-circle-outline red"></i>
                      </td>
                      <td>2.5454545</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>25-07-2019</td>
                      <td>
                        <i className="icon ion-md-checkmark-circle-outline green"></i>
                      </td>
                      <td>1.45894147</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>25-08-2019</td>
                      <td>
                        <i className="icon ion-md-close-circle-outline red"></i>
                      </td>
                      <td>2.5454545</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
