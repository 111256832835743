import React, { useContext, useEffect, useRef } from "react";
import * as echarts from "echarts";
import { UserContext } from "context/UserContext";

const EarningsChart: React.FC<{}> = () => {
  const { auth } = useContext(UserContext);
  const pieChartRef = useRef<HTMLDivElement>(null);
  const barRaceChartRef = useRef<HTMLDivElement>(null);
  const lineChartRef = useRef<HTMLDivElement>(null);

  const walletBalance = auth.user.wallet.balance || 0;
  const referralBalance = auth.user.referralBalance || 0;
  const rebateBalance = auth.user.rebateBalance || 0;
  const rewardBalance = referralBalance + rebateBalance;
  const hasData =
    walletBalance !== 0 || referralBalance !== 0 || rebateBalance !== 0;

  const sampleData = [
    { value: 300, name: "Current Balance" },
    { value: 200, name: "Referral Balance" },
    { value: 100, name: "Rebate Balance" },
  ];

  const pieData = [
    { value: referralBalance.toFixed(1), name: "Referral Balance" },
    { value: rebateBalance.toFixed(1), name: "Rebate Balance" },
  ];

  const isMobile = window.innerWidth < 1024;

  useEffect(() => {
    if (pieChartRef.current) {
      const pieChart = echarts.init(pieChartRef.current);
      const pieChartOption = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          bottom: "0%",
          textStyle: {
            color: "#FFF",
          },
        },
        series: [
          {
            name: "Earnings",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },

            labelLine: {
              show: false,
            },
            data: hasData ? pieData : sampleData,
          },
        ],
      };

      pieChart.setOption(pieChartOption);
    }

    if (!isMobile && barRaceChartRef.current) {
      const barRaceChart = echarts.init(barRaceChartRef.current);
      const barRaceOption = {
        title: {
          text: "",
          left: "center",
          textStyle: {
            color: "#FFF",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: ["Earnings"],
          textStyle: {
            color: "#FFF",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          axisLine: {
            lineStyle: {
              color: "#FFF",
            },
          },
        },
        yAxis: {
          type: "category",
          data: ["Current Balance", "Referral Balance", "Rebate Balance"],
          axisLine: {
            lineStyle: {
              color: "#FFF",
            },
          },
        },
        series: [
          {
            name: "Earnings",
            type: "bar",
            data: hasData
              ? [walletBalance.toFixed(1), referralBalance.toFixed(1), rebateBalance.toFixed(1)]
              : [300, 200, 100],
            itemStyle: {
              color: (params: any) => {
                const colors = ["#36A2EB", "#FF6384", "#FFCE56"];
                return colors[params.dataIndex];
              },
            },
          },
        ],
      };

      barRaceChart.setOption(barRaceOption);
    }

    if (isMobile && lineChartRef.current) {
      const lineChart = echarts.init(lineChartRef.current);
      const lineChartOption = {
        title: {
          text: "Earnings Trend",
          left: "center",
          textStyle: {
            color: "#FFF",
          },
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: ["Current Balance", "Referral Balance", "Rebate Balance"],
          axisLine: {
            lineStyle: {
              color: "#FFF",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#FFF",
            },
          },
        },
        series: [
          {
            name: "Earnings",
            type: "line",
            data: hasData
              ? [walletBalance, referralBalance, rebateBalance]
              : [300, 200, 100],
            itemStyle: {
              color: "#36A2EB",
            },
          },
        ],
      };

      lineChart.setOption(lineChartOption);
    }
  }, [walletBalance, referralBalance, rebateBalance, hasData, isMobile]);

  return (
    <div className="flex flex-col lg:flex-row items-center gap-4 text-white h-full rounded-md shadow-md w-full">
      <div
        className={`w-full ${
          isMobile ? "hidden lg:block" : "lg:w-2/3"
        } bg-[#121417] py-4 px-2 h-96 relative`}
      >
        <div ref={barRaceChartRef} style={{ width: "100%", height: "100%" }} />
        {!hasData && (
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <p className="text-white text-xl text-center">
              After the data is visible, you will see the charts!
            </p>
          </div>
        )}
      </div>
      <div
        className={`w-full ${
          !isMobile ? "hidden" : ""
        } lg:hidden bg-[#121417] py-4 px-2 h-96 relative`}
      >
        <div ref={lineChartRef} style={{ width: "100%", height: "100%" }} />
        {!hasData && (
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <p className="text-white text-xl text-center">
              After the data is visible, you will see the charts!
            </p>
          </div>
        )}
      </div>
      <div className="w-full lg:w-1/3 bg-[#121417] p-4 h-96 flex flex-col relative">
        <p className="text-sm text-white font-bold">Total Reward Earnings</p>
        <p className="font-bold text-2xl">${rewardBalance.toFixed(1)}</p>
        <div ref={pieChartRef} style={{ width: "100%", height: "100%" }} />
        {!hasData && (
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <p className="text-white text-xl text-center">
              After the data is visible, you will see the charts!
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default EarningsChart;
