import { useContext } from "react";
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { defaultAuth, UserContext } from "../../context/UserContext";
import Logo from "../../assets/img/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const LoggedInDropdown = () => {
  let history = useHistory();
  const { auth, setAuth } = useContext(UserContext);
  const logoutButtonHandler = () => {
    setAuth(defaultAuth);
    localStorage.removeItem("vTrading-user");
    history.push("/");
  };

  return (
    <Dropdown className="header-img-icon">
      <Dropdown.Toggle variant="default">
        <img src={"img/avatar.svg"} alt="avatar" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className="dropdown-header d-flex flex-column align-items-center">
          <div className="figure mb-3">
            <img src={"img/avatar.svg"} alt="" />
          </div>
          <div className="info text-center">
            <p className="name font-weight-bold mb-0">{auth.user.fullName}</p>
            <p className="email text-muted mb-3">{auth.user.email}</p>
          </div>
        </div>
        <div className="dropdown-body">
          <ul className="profile-nav">
            <li className="nav-item">
              <Link to="/profile" className="nav-link">
                <i className="icon ion-md-person"></i>
                <span>Profile</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/wallet" className="nav-link">
                <i className="icon ion-md-wallet"></i>
                <span>My Wallet</span>
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link to="/settings" className="nav-link">
                <i className="icon ion-md-settings"></i>
                <span>Settings</span>
              </Link>
            </li> */}
            <li className="nav-item">
              <Link
                to="/"
                onClick={logoutButtonHandler}
                className="nav-link red"
              >
                <i className="icon ion-md-power"></i>
                <span>Log Out</span>
              </Link>
            </li>
          </ul>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default function Header() {
  const { auth } = useContext(UserContext);

  return (
    <>
      <header className="light-bb">
        <Navbar expand="lg">
          <Link className="navbar-brand" to="/">
            <img src={Logo} alt="logo" />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <FontAwesomeIcon icon={faBars} color="#c5cbce" />
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="navbar-nav mr-auto">
              <Link to="/exchange" className="nav-link">
                Start Trading
              </Link>
              <Link to="/about-us" className="nav-link">
                About Us
              </Link>
            </Nav>
            <Nav className="navbar-nav ml-auto">
              {auth?.signedIn ? (
                <LoggedInDropdown />
              ) : (
                <>
                  <Link to="/login" className="btn-1">
                    Sign In
                  </Link>
                  <Link to="/signup" className="btn-2">
                    Get Started
                  </Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>
    </>
  );
}
