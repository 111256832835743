import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import { UserContext } from "../context/UserContext";
import MainLayout from "../components/layout";
import { LoginAPI } from "api/common/api";

export default function Login() {
  let history = useHistory();
  const { auth, setAuth, updateUser } = useContext(UserContext);

  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const handleValidation = () => {
    const { password, email } = values;
    if (!password || !email) {
      toast.error("Email and Password are required");
      return false;
    }
    return true;
  };
  const handleChange = (e: any) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const areInputsValid = handleValidation();
    if (!areInputsValid) {
      // show errors then return
      return;
    }
    const { response, errors } = await LoginAPI(values);
    if (errors) {
      // handle errors
      // and then return
      errors.map((err: any) => toast.error(err.message));
      return;
    }
    if (!response || !response.success) {
      // handle "SERVER NOT RESPONDING CORRECTLY" error
      // and then return
      toast.error("Server is not responding correctly. Please Try again.");
      return;
    }

    // if the code reaches this place it means that
    // the response contains the user info + auth_token
    // save them to the local storage and the store context you're using
    // so that you can use them later on other requests
    // like - place a  bet - get last transactions ...
    const { auth_token, user } = response;
    localStorage.setItem("vTrading-user", JSON.stringify({ auth_token }));
    setAuth({ signedIn: true, auth_token, user });
    updateUser();
    history.push("/exchange");
  };
  useEffect(() => {
    if (auth && auth.signedIn) {
      history.push("/exchange");
      updateUser();
    }
  }, [history, auth, updateUser]);
  return (
    <MainLayout>
      <div className="vh-100 d-flex justify-content-center">
        <div className="form-access my-auto">
          <form onSubmit={handleSubmit}>
            <span>Sign In</span>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Email Address"
                name="email"
                value={values.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                name="password"
                value={values.password}
                onChange={handleChange}
                required
              />
            </div>
            <div className="text-right">
              <Link to="/reset">Forgot Password?</Link>
            </div>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="form-checkbox"
              />
              <label className="custom-control-label" htmlFor="form-checkbox">
                Remember me
              </label>
            </div>
            <button type="submit" className="btn btn-primary">
              Sign In
            </button>
          </form>
          <h2>
            Don't have an account? <Link to="/signup">Sign up here</Link>
          </h2>
        </div>
      </div>
    </MainLayout>
  );
}
