import { Bet } from "api/common/api";
import React from "react";
import { capitalizeFirstLetter } from "utils/capitalize";

interface Props {
  betsLog: Bet[];
}

const TradesLog = ({ betsLog }: Props) => {
  return (
    <div className="trades-log">
      <h3>Trades Log</h3>
      <div className="table-wrapper">
        <table className="table">
          <thead>
            <tr>
              <th>SYMBOL</th>
              <th>AMOUNT</th>
              <th>PROFIT</th>
              <th>HIGH/LOW</th>
              <th>RESULT</th>
              <th>STATUS</th>
              <th>DATE</th>
            </tr>
          </thead>
          <tbody>
            {betsLog.map(
              ({ id, betAmount, profit, prediction, result, createdAt }) => {
                return (
                  <tr key={id}>
                    <td>{"BTC/USDT"}</td>
                    <td>{betAmount}</td>
                    <td>
                      {result !== "not processed" ? (
                        <span
                          className={
                            profit === 0 ? "draw" : profit > 0 ? "pos" : "neg"
                          }
                        >
                          ${profit.toFixed(2)}
                        </span>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                    <td>
                      <span className={prediction === "low" ? "neg" : "pos"}>
                        {capitalizeFirstLetter(prediction)}
                      </span>
                    </td>
                    {result !== "not processed" ? (
                      <>
                        <td>
                          <span
                            className={
                              result === "win"
                                ? "pos"
                                : result === "draw"
                                ? "draw"
                                : "neg"
                            }
                          >
                            {capitalizeFirstLetter(result)}
                          </span>
                        </td>
                        <td>
                          <span className="pos">Complete</span>
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          <span>Pending</span>
                        </td>
                        <td>
                          <span>Running</span>
                        </td>
                      </>
                    )}

                    <td>{new Date(createdAt).toLocaleString()}</td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TradesLog;
