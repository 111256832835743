import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { authType, defaultAuth, UserContext } from "./context/UserContext";
import Index from "./pages";
import { CurrentUserAPI } from "api/common/api";

export default function App() {
  const initialAuthState = useMemo(
    () => ({
      signedIn: false,
    }),
    []
  );
  const [auth, setAuth] = useState<authType>(defaultAuth);
  const [renderProfile, setRenderProfile] = useState(false);
  const updateUser = useCallback(async () => {
    const localAuth = JSON.parse(localStorage.getItem("vTrading-user") || "{}");
    if (localAuth) {
      const { response, errors } = await CurrentUserAPI({
        auth_token: localAuth.auth_token,
      });
      // if (errors) {
      //   errors.map((err: any) => console.log(err));
      //   setAuth(defaultAuth);
      //   localStorage.removeItem("vTrading-user");
      //   setRenderProfile(true);
      //   return;
      // }
      if (!response) {
        console.log("Error connecting to server");
        return;
      }
      setAuth({
        signedIn: true,
        auth_token: localAuth.auth_token,
        user: response.user,
      });
      setRenderProfile(true);
    } else {
      setAuth(defaultAuth);
      setRenderProfile(true);
    }
  }, []);

  const userProviderValue = useMemo(
    () => ({ auth, setAuth, updateUser }),
    [auth, setAuth, updateUser]
  );

  useEffect(() => {
    updateUser();
    let timer = setInterval(() => {
      updateUser();
    }, 20 * 200);
    return () => clearInterval(timer);
  }, [initialAuthState, updateUser]);

  return (
    <>
      <BrowserRouter>
        <UserContext.Provider value={userProviderValue}>
          <Index />
          <ToastContainer
            position={"bottom-right"}
            autoClose={8000}
            pauseOnHover
            draggable
            theme={"dark"}
          />
        </UserContext.Provider>
      </BrowserRouter>
    </>
  );
}

// const ScrollToTop = () => {
//   window.scrollTo(0, 0);
//   return null;
// };
