import React, { Component } from "react";

class TradeChart extends Component {
  myRef: any;
  constructor(props: any) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    const scriptSrc = document.createElement("script");
    const script = document.createElement("script");
    scriptSrc.async = true;
    script.innerHTML = `new window.TradingView.widget(
      {
        "width": "100%",
        "height": 350,
      "symbol": "BINANCE:BTCUSDT",
      "interval": "1",
      "timezone": "Etc/UTC",
      "theme": "dark",
      "style": "1",
      "locale": "en",
      "toolbar_bg": "#f1f3f6",
      "enable_publishing": false,
      "hide_legend": true,
      "save_image": false,
      studies: ["BB@tv-basicstudies"],
      "container_id": "tradingview_7bf97"
    }
      );`;
    this.myRef.current.appendChild(scriptSrc);
    this.myRef.current.appendChild(script);
  }
  render() {
    return (
      <div className="main-chart mb15">
        <h1>BTC/USDT</h1>
        <div className="tradingview-widget-container" ref={this.myRef}>
          <div id="tradingview_7bf97"></div>
        </div>
      </div>
    );
  }
}

export default TradeChart;
