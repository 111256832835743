import React from "react";

const LandingSub = () => {
  return (
    <div className="landing-sub">
      <div className="container">
        <div className="row">
          <div className="offset-md-1 col-md-10">
            <div className="landing-sub-content">
              <h2>
                Become part of a global community of people who have found their
                path to the crypto world with vTrading
              </h2>
              <a href="signup-dark.html">Get Started</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingSub;
