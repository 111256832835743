import React from "react";
import Strog from "../../assets/img/landing/stroge.svg";
import Backup from "../../assets/img/landing/backup.svg";
import Management from "../../assets/img/landing/managment.svg";

const LandingFeature = () => {
  return (
    <div className="landing-feature">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2>Why Choose Us?</h2>
          </div>
          <div className="col-md-4">
            <div className="landing-feature-item">
              <img src={Strog} alt="" />
              <h3>Secure Storage</h3>
              <p>
                98% of our user-balances are stored in a cold-storage. Hence, no
                chance of hacking
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="landing-feature-item">
              <img src={Backup} alt="" />
              <h3>Insurance Protected</h3>
              <p>
                Our Cold and Hot both wallets are insured by the world’s top
                banks/companies
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="landing-feature-item">
              <img src={Management} alt="" />
              <h3>Industry Best Practices</h3>
              <p>
                Our state-of-the art technology experienced and team all are
                under 24/7 monitoring.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingFeature;
