import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { UserContext } from "../../context/UserContext";
import { GetDepositWalletsAPI, VerifyDepositAPI } from "api/common/api";

const Deposit = () => {
  const { auth, updateUser } = useContext(UserContext);
  const [depositAddress, setDepositAddress] = useState("");
  const [hash, setHash] = useState("");

  const verifyDeposit = async (e: any) => {
    e.preventDefault();
    if (!hash) {
      toast.error("Please enter your transaction hash");
      return;
    }
    const { response, errors } = await VerifyDepositAPI({
      auth_token: auth.auth_token,
      hash,
    });
    if (errors) {
      errors.map((err: any) => toast.error(err.message));
      return;
    }
    if (!response || !response.success) {
      // handle "SERVER NOT RESPONDING CORRECTLY" error
      // and then return
      toast.error("Server is not responding correctly. Please Try again.");
      return;
    }
    const amount = response.amount;
    toast.success(`${amount}$ has been placed into your balance`);
    updateUser();
  };
  const handleHashChange = (e: any) => {
    e.preventDefault();
    setHash(e.target.value);
  };

  const getAddress = async () => {
    const { errors, response } = await GetDepositWalletsAPI();
    if (errors) {
      return errors.map((err: any) => toast.error(err.message));
    }
    if (!response || !response.success) {
      return toast.error(
        "Server is not responding correctly. Please Try again."
      );
    }
    setDepositAddress(response.depositWallets[0]);
  };

  useEffect(() => {
    getAddress();
  }, []);

  return (
    <>
      <div className="card">
        <div className="card-body deposit-steps">
          <h5 className="card-title">Deposit Balance:</h5>
          <div className="row wallet-address">
            <div className="col-md-8">
              <ol>
                <li>
                  <p>
                    Please copy the deposit address shown below by clicking on
                    copy or scanning the qr code.
                  </p>
                </li>
                <li>
                  <p>Desposit the balance to the given address.</p>
                </li>
                <li>
                  <p>
                    Click <a href="#verify">Here</a> to validate the
                    transaction.
                  </p>
                </li>
              </ol>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  value={depositAddress}
                  disabled
                />
                <div className="input-group-prepend">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      navigator.clipboard.writeText(depositAddress);
                    }}
                  >
                    Copy
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <img src={"img/qr-code-light.svg"} alt="qr-code" />
            </div>
          </div>
        </div>
      </div>
      <div className="card" id="verify">
        <div className="card-body deposit-steps">
          <h5 className="card-title">Verify The transaction:</h5>
          <div className="row wallet-address">
            <div className="col-md-8">
              <ol>
                <li>
                  <p>Paste the Transaction ID (trx id) in the field bellow.</p>
                </li>
                <li>
                  <p>Click Verify</p>
                </li>
                <li>
                  <p>
                    Once verified, your balance will update automatically based
                    on the amount you sent to us.
                  </p>
                </li>
              </ol>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  value={hash}
                  onChange={handleHashChange}
                  placeholder="Enter the transaction hash"
                />
                <div className="input-group-prepend">
                  <button onClick={verifyDeposit} className="btn btn-primary">
                    Verify
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Deposit;
