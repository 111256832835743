import {
  AuthenticatedPropsGenerator,
  GetReferralReward,
  Response,
} from "../_types";
import { GetConfigurationValuesResponse } from "../_types/config";
import { getAuthenticatedApi } from "../apiGenerator";

export const GetReferralRewardAPI = ({
  auth_token,
}: AuthenticatedPropsGenerator<{}>): Promise<Response<GetReferralReward>> =>
  getAuthenticatedApi<GetReferralReward>({
    url: `/user/referral-reward`,
    auth_token,
  });

export const GetReferralSettingsAPI = ({
  auth_token,
}: AuthenticatedPropsGenerator<{}>): Promise<
  Response<GetConfigurationValuesResponse>
> =>
  getAuthenticatedApi<GetConfigurationValuesResponse>({
    url: `/user/settings`,
    auth_token,
  });
